import React from "react";
import PropTypes from "prop-types";
import ResultMessage from "../../reuseables/ResultMessage";

export default function CurrentPlan({ currententPlan }) {
  return (
    <>
      {currententPlan.loader.status === "loading" && (
        <div className="w-full h-52 p-6 bg-white flex justify-between my-6 my_rounded">
          <div className="my_rounded h-full bg_greay w-3/12"></div>
          <div className="my_rounded h-full  bg_greay w-8/12"></div>
        </div>
      )}
      {currententPlan.loader.status === "failed" && (
        <ResultMessage message={currententPlan.loader.text} />
      )}
      {currententPlan.loader.status === "done" && (
        <div className="flex my-6">
          <div className=" md:border-l md:pl-10  ml-4">
            <h5 className="font-bold">أشتراكك الحالي</h5>
            <div className="bg-white w-fit  my_rounded mt-4 p-8 flex flex-col  ">
              <figure
                style={{
                  backgroundColor: currententPlan.data.color
                    ? currententPlan.data.color
                    : "#B44AFF",
                }}
                className="my_rounded w-20 min-h-20 p-2 mb-2 m-auto flex items-center justify-center "
              >
                <img className="block w-full" src={currententPlan.data.logo} />
              </figure>
              <h5 className="font-bold m-auto">{currententPlan.data.name}</h5>
              <h5 className="font-bold m-auto text_secondary">
                {currententPlan.data.duration}
              </h5>
              <h5 className="m-auto mt-3">
                {currententPlan.data.price} دولار /{" "}
                {currententPlan.data.duration}
              </h5>
            </div>
          </div>
          <div>
            <h5 className="font-bold">محتويات هذه الباقة</h5>
            <div className="mt-8">
              <h5 className="mt-2">
                المساحة التخزينية {currententPlan.data.storage}{" "}
                <span className="font-bold">GB</span>
              </h5>
              <h5 className="mt-2">
                عدد الكورسات المتاحة {currententPlan.data.courses}{" "}
                <span className="font-bold">كورس</span>
              </h5>
              <h5 className="mt-2">
                عدد الطلبة {currententPlan.data.students}{" "}
                <span className="font-bold">طالب</span>
              </h5>
              <h5 className="mt-2 mx-auto">
                {currententPlan.data?.characteristics.map((elem, index) => (
                  <span key={index}>
                    {elem}{" "}
                    {index + 1 != currententPlan.data.characteristics?.length &&
                      ","}{" "}
                  </span>
                ))}
              </h5>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
CurrentPlan.propTypes = {
  currententPlan: PropTypes.any,
};
