import React from "react";

export default function GroupIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
    >
      <circle
        cx="2.83688"
        cy="2.83688"
        r="2.83688"
        transform="matrix(-1 0 0 1 12.0156 0.79422)"
        stroke="url(#paint0_linear_4077_5195)"
        strokeWidth="1.25"
      />
      <path
        d="M4.21094 10.6771C4.21094 10.0669 4.59452 9.52259 5.16916 9.31736V9.31736C7.7599 8.3921 10.5911 8.3921 13.1818 9.31736V9.31736C13.7564 9.52259 14.14 10.0669 14.14 10.6771V11.6101C14.14 12.4522 13.3941 13.0991 12.5604 12.98L12.2825 12.9403C10.2216 12.6459 8.12935 12.6459 6.06848 12.9403L5.79052 12.98C4.95683 13.0991 4.21094 12.4522 4.21094 11.6101V10.6771Z"
        stroke="url(#paint1_linear_4077_5195)"
        strokeWidth="1.25"
      />
      <path
        d="M13.4351 6.54652C14.6661 6.54652 15.6641 5.54857 15.6641 4.31754C15.6641 3.08651 14.6661 2.08856 13.4351 2.08856"
        stroke="url(#paint2_linear_4077_5195)"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M15.8752 11.6318L16.0936 11.663C16.7486 11.7566 17.3347 11.2483 17.3347 10.5866V9.85353C17.3347 9.3741 17.0333 8.94642 16.5818 8.78517C16.1314 8.62433 15.6718 8.49907 15.207 8.40939"
        stroke="url(#paint3_linear_4077_5195)"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M4.56491 6.54652C3.33388 6.54652 2.33594 5.54857 2.33594 4.31754C2.33594 3.08651 3.33388 2.08856 4.56491 2.08856"
        stroke="url(#paint4_linear_4077_5195)"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M2.1248 11.6318L1.90641 11.663C1.25137 11.7566 0.665309 11.2483 0.665309 10.5866V9.85353C0.665309 9.3741 0.966697 8.94642 1.4182 8.78517C1.86857 8.62433 2.32819 8.49907 2.79297 8.40939"
        stroke="url(#paint5_linear_4077_5195)"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4077_5195"
          x1="-6.57427e-06"
          y1="5.67375"
          x2="5.67375"
          y2="-6.57427e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D68DF" />
          <stop offset="1" stopColor="#23DFF8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4077_5195"
          x1="4.21093"
          y1="13.2057"
          x2="8.63916"
          y2="4.93965"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D68DF" />
          <stop offset="1" stopColor="#23DFF8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4077_5195"
          x1="15.6641"
          y1="6.54651"
          x2="12.0977"
          y2="4.76333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D68DF" />
          <stop offset="1" stopColor="#23DFF8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4077_5195"
          x1="15.207"
          y1="11.6741"
          x2="18.1938"
          y2="9.72761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D68DF" />
          <stop offset="1" stopColor="#23DFF8" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4077_5195"
          x1="2.33593"
          y1="6.54651"
          x2="5.9023"
          y2="4.76333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D68DF" />
          <stop offset="1" stopColor="#23DFF8" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4077_5195"
          x1="2.79297"
          y1="11.6741"
          x2="-0.193786"
          y2="9.72761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D68DF" />
          <stop offset="1" stopColor="#23DFF8" />
        </linearGradient>
      </defs>
    </svg>
  );
}
