import React, { useState } from "react";
import PropTypes from "prop-types";
import HideIcon from "../../icons/HideIcon";
import DocumentIcon from "../../icons/DocumentIcon";
import VideoIcon from "../../icons/VideoIcon";
import GroupIcon from "../../icons/GroupIcon";
import CustomSubmitButton from "../../reuseables/CustomSubmitButton";
import SucsessMessage from "../../reuseables/SucsessMessage";
import ShowIcon from "../../icons/ShowIcon";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import { useParams } from "react-router-dom";

export default function HideCourse({
  cardDetailes,
  close,
  targetStatus,
  type,
  regetCourseDetailes,
  regetList,
  id,
}) {
  const prams = useParams();
  const [loader, setloader] = useState({ status: "" });
  const [submitErr, setsubmitErr] = useState();
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    setloader({ status: "loading" });
    setsubmitErr("");
    let Url = type == "course" ? `courses/${prams.id}` : `lessons/${id}`;
    myFeach(Url, "PATCH", {}, "token")
      .then((data) => {
        console.log(data);
        setloader({ status: "done" });
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message:
            type == "course"
              ? "تم تغير حالة الكورس بنجاح"
              : "تم تغير حالة الدرس بنجاح",
        });
        setTimeout(() => {
          regetCourseDetailes();
          type == "course" && regetList();
          close();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        setloader({ status: "failed" });
        setsubmitErr(
          parceErr(err)?.error ? parceErr(err).error : "فشل تغير حالة الرؤية "
        );
        setopenFeedBackMessage({
          status: true,
          message: parceErr(err)?.error
            ? parceErr(err).error
            : "فشل تغير حالة الرؤية",
        });
      });
  };
  return (
    <>
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <div>
        <button onClick={close} className="modal_bg"></button>
        <div className="modal_content ">
          <div className="flex w-full justify-center mb-8 p-10 ">
            {targetStatus == "hide" ? (
              <div className="bg_danger circle_rounded flex items-center justify-center w-9 h-9 mx-2">
                <HideIcon />
              </div>
            ) : (
              <div className="bg_success circle_rounded flex items-center justify-center w-9 h-9 mx-2">
                <ShowIcon color="white" />
              </div>
            )}
            <h4
              className={`font-bold  ${targetStatus == "hide" ? "text_danger" : "text_sucsess"} `}
            >
              {targetStatus == "hide"
                ? `هل ترغب في إخفاء هذا ${type === "course" ? "الكورس" : "الدرس"} ؟`
                : `هل ترغب في إظهار هذا ${type === "course" ? "الكورس" : "الدرس"} ؟`}
            </h4>
          </div>
          {type === "course" && (
            <div className="px-10 mb-4">
              <div className="w-fit bg_greay p-2 my_rounded flex flex-wrap md:flex-nowrap  ">
                <figure className="h-20 w-40 ml-2 mb-4 md:mb-0">
                  <div
                    className="imge_bg my_rounded"
                    style={{
                      backgroundImage: `url(${cardDetailes?.image})`,
                    }}
                  ></div>
                </figure>
                <div className="flex justify-between w-full flex-wrap">
                  <div className="">
                    <div className="flex flex-wrap ">
                      <h5 className="font-bold">{cardDetailes?.name}</h5>
                    </div>
                    <div className="flex md:mt-4 flex-wrap mt-2 ">
                      <div className="flex items-center ml-4 ">
                        <GroupIcon />
                        <h5 className="mx-2">
                          {" "}
                          {cardDetailes?.noOfStudents}طالب
                        </h5>
                      </div>
                      <div className="flex items-center ml-4 ">
                        <VideoIcon />
                        <h5 className="mx-2">
                          {cardDetailes?.noOfVideos}فيديو{" "}
                        </h5>
                      </div>
                      <div className="flex items-center ">
                        <DocumentIcon />
                        <h5 className="mx-2">{cardDetailes?.noOfPdfs}مستند </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {type === "leson" && (
            <div className="w-full">
              <div className="my_rounded bg_greay items-center m-auto flex p-4 w-fit mb-4">
                <h4 className="">{cardDetailes.name}</h4>
                {cardDetailes.videoExists && (
                  <div className="mx-1">
                    <VideoIcon iconColor="greay" />
                  </div>
                )}
                {cardDetailes.pdfExists && (
                  <div className="mx-1">
                    <DocumentIcon iconColor="greay" />
                  </div>
                )}
              </div>
            </div>
          )}
          <form
            className="border_top_greay pt-10 pb-4 w-full flex justify-center "
            onSubmit={handleSubmit}
          >
            <CustomSubmitButton
              loader={loader}
              err={submitErr}
              width="112px"
              height="32px"
              title="تأكيد"
            />
            <button
              type="button"
              className="mx-2 w-28 h-8 gradient_border"
              onClick={close}
            >
              إلغاء
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
HideCourse.propTypes = {
  cardDetailes: PropTypes.object,
  close: PropTypes.func,
  regetCourseDetailes: PropTypes.func,
  regetList: PropTypes.func,
  targetStatus: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
};
