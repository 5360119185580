import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { cutString } from "../../globalFunctions/TextModify";
import InputScaleable from "./InputScaleable";

export default function PhoneInput({
  lable = "",
  placeHolder = "",
  required = false,
  type = "text",
  name = "",
  setformData,
  formData,
  fontSize = "20px",
  styleClass = "",
  containerClass = "",
  value = "",
  comment,
  disabled = false,
  getOldDataLoader = "done",
}) {
  const [country, setcountry] = useState("");
  const [inputValue, setinputValue] = useState(
    value ? value : formData?.data[name]
  );
  const onChange = (e) => {
    const { value } = e.target;
    if (/^(\+|\d)[\d]*$/.test(value) || !value) {
      setinputValue(e.target.value);
      setformData({
        ...formData,
        data: { ...formData?.data, [name]: e.target.value },
        errors: e.target.value
          ? { ...formData.errors, [name]: "" }
          : { ...formData.errors },
      });
    }
  };

  useEffect(() => {
    onChange({ target: { value: country } });
  }, [country]);

  useEffect(() => {
    if (formData.data[name] && getOldDataLoader == "done") {
      setinputValue(formData.data[name]);
    }
  }, [getOldDataLoader]);
  return (
    <>
      {getOldDataLoader == "done" ? (
        <div className={`flex flex-col relative my-4 ${containerClass}`}>
          <label className="font-bold text-sm">
            {lable} {required && <span className="text_danger">*</span>}{" "}
          </label>
          <div className="w-full flex justify-between items-center">
            <input
              value={inputValue}
              name={name}
              //   type="number"
              disabled={
                disabled || (!country && !formData.data[name]) ? true : false
              }
              pattern="[\d+]*"
              className={` w-full ${styleClass} mt-2 ${type == "password" ? "pl-9" : ""} ${formData?.errors[name] ? "danger_border" : ""} direction_left`}
              placeholder={placeHolder}
              style={{ fontSize: fontSize }}
              onChange={onChange}
            />
            {/* <input value={country} disabled className="direction_left w-14 mt-2" /> */}
            <select
              disabled={disabled}
              className={`w-auto mr-1 mt-2 ${formData?.errors[name] && !country ? "danger_border" : ""}`}
              onChange={(e) => setcountry(e.target.value)}
              value={
                formData.data[name]
                  ? extractPhoneNumber(formData.data[name]).key
                  : ""
              }
            >
              {allKeys.map((elem, index) => (
                <option key={index} value={elem.phoneKey}>
                  {elem.country}
                </option>
              ))}
            </select>
          </div>
          {!formData?.errors[name] && (
            <h6 className="text-xs text_primary mt-1">{comment}</h6>
          )}
          <h4 className="text-xs text_danger">
            {cutString(formData?.errors[name], 160)}
          </h4>
        </div>
      ) : (
        <InputScaleable width="70%" height="40px" />
      )}
    </>
  );
}
PhoneInput.propTypes = {
  width: PropTypes.string,
  getOldDataLoader: PropTypes.string,
  value: PropTypes.string,
  height: PropTypes.string,
  lable: PropTypes.string,
  comment: PropTypes.string,
  lang: PropTypes.string,
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  fontSize: PropTypes.string,
  containerClass: PropTypes.string,
  styleClass: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  setformData: PropTypes.any,
  formData: PropTypes.object,
  options: PropTypes.array,
};
const allKeys = [
  {
    country: "",
    phoneKey: "",
  },
  {
    country: "قطر",
    phoneKey: "+974",
  },
  {
    country: "السعودية",
    phoneKey: "+966",
  },
  {
    country: "المغرب",
    phoneKey: "+212",
  },
  {
    country: "الإمارات",
    phoneKey: "+971",
  },
  {
    country: "البحرين",
    phoneKey: "+973",
  },
  {
    country: "عمان",
    phoneKey: "+968",
  },
  {
    country: "الكويت",
    phoneKey: "+965",
  },
  {
    country: "الأردن",
    phoneKey: "+962",
  },
  {
    country: "مصر",
    phoneKey: "+20",
  },
  {
    country: "سوريا",
    phoneKey: "+963",
  },
  {
    country: "تونس",
    phoneKey: "+216",
  },
  {
    country: "ليبيا",
    phoneKey: "+218",
  },
  {
    country: "فلسطين",
    phoneKey: "+970",
  },
  {
    country: "الجزائر",
    phoneKey: "+213",
  },
  {
    country: "موريتانيا",
    phoneKey: "+222",
  },
  {
    country: "لبنان",
    phoneKey: "+961",
  },
  {
    country: "السودان",
    phoneKey: "+249",
  },
  {
    country: "العراق",
    phoneKey: "+964",
  },
  {
    country: "اليمن",
    phoneKey: "+967",
  },
  {
    country: "جيبوتي",
    phoneKey: "+253",
  },
  {
    country: "الصومال",
    phoneKey: "+252",
  },
  {
    country: "جزر القمر",
    phoneKey: "+269",
  },
];
function extractPhoneNumber(
  phoneNumber,
  array = allKeys.map((elem) => elem.phoneKey)
) {
  let key = "";
  let number = "";
  for (let i = 1; i < array.length; i++) {
    if (phoneNumber.startsWith(array[i])) {
      key = array[i];
      number = phoneNumber.slice(array[i].length);
      break;
    }
  }

  return { key, number };
}
