import React from "react";

export default function MonyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_4002_43440)">
        <path
          d="M0 5L0 8.33333H0.833333C1.27536 8.33333 1.69928 8.50893 2.01184 8.82149C2.32441 9.13405 2.5 9.55797 2.5 10C2.5 10.442 2.32441 10.866 2.01184 11.1785C1.69928 11.4911 1.27536 11.6667 0.833333 11.6667H0L0 15C0 15.663 0.263392 16.2989 0.732233 16.7678C1.20107 17.2366 1.83696 17.5 2.5 17.5H20V11.6667H19.1667C18.7246 11.6667 18.3007 11.4911 17.9882 11.1785C17.6756 10.866 17.5 10.442 17.5 10C17.5 9.55797 17.6756 9.13405 17.9882 8.82149C18.3007 8.50893 18.7246 8.33333 19.1667 8.33333H20V2.5L2.5 2.5C1.83696 2.5 1.20107 2.76339 0.732233 3.23223C0.263392 3.70107 0 4.33696 0 5ZM18.3333 6.77167C17.6171 6.95583 16.9824 7.37297 16.5292 7.95742C16.076 8.54186 15.83 9.26043 15.83 10C15.83 10.7396 16.076 11.4581 16.5292 12.0426C16.9824 12.627 17.6171 13.0442 18.3333 13.2283V15.8333L14.1667 15.8333V13.3333H12.5V15.8333H2.5C2.27899 15.8333 2.06702 15.7455 1.91074 15.5893C1.75446 15.433 1.66667 15.221 1.66667 15V13.2283C2.38294 13.0442 3.01762 12.627 3.47081 12.0426C3.924 11.4581 4.16995 10.7396 4.16995 10C4.16995 9.26043 3.924 8.54186 3.47081 7.95742C3.01762 7.37297 2.38294 6.95583 1.66667 6.77167V5C1.66667 4.77899 1.75446 4.56702 1.91074 4.41074C2.06702 4.25446 2.27899 4.16667 2.5 4.16667H12.5V6.66667H14.1667V4.16667L18.3333 4.16667V6.77167Z"
          fill="#1D68DF"
        />
        <path
          d="M12.5 9.16675V10.8334C12.5 11.2937 12.8731 11.6667 13.3333 11.6667C13.7936 11.6667 14.1667 11.2937 14.1667 10.8334V9.16675C14.1667 8.70651 13.7936 8.33342 13.3333 8.33342C12.8731 8.33342 12.5 8.70651 12.5 9.16675Z"
          fill="#1D68DF"
        />
        <circle cx="6.52563" cy="8.65234" r="0.70922" fill="#1D68DF" />
        <path
          d="M8.86362 8.12319L5.99515 10.9917C5.73894 11.2479 5.73894 11.6633 5.99515 11.9195C6.25135 12.1757 6.66674 12.1757 6.92294 11.9195L9.79142 9.05099C10.0476 8.79478 10.0476 8.3794 9.79142 8.12319C9.53521 7.86699 9.11983 7.86699 8.86362 8.12319Z"
          fill="#1D68DF"
        />
        <circle cx="9.29186" cy="11.5602" r="0.780142" fill="#1D68DF" />
      </g>
      <defs>
        <clipPath id="clip0_4002_43440">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="matrix(0 -1 1 0 0 20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
