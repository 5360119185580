export const checkRequired = (
  dataobject = {},
  required = [],
  formData,
  setformData
) => {
  let myErrors = {};
  let generalErr = "";
  for (let key of required) {
    if (
      (dataobject[key] === "" ||
        dataobject[key] === undefined ||
        dataobject[key] === null ||
        dataobject[key].length == 0) &&
      required.includes(key)
    ) {
      myErrors = { ...myErrors, [key]: "هذا الحقل مطلوب" };
      generalErr = " قم بمليء الحقول المطلوبة أولاً";
    }
    if (
      key == "confirmPassword" &&
      dataobject.confirmPassword !== dataobject.password &&
      dataobject.confirmPassword
    ) {
      myErrors = { ...myErrors, [key]: "كلمة سر غير مطابقة" };
    }
    if (
      key == "confirmResetPassword" &&
      dataobject.confirmResetPassword !== dataobject.newPassword &&
      dataobject.confirmResetPassword
    ) {
      myErrors = { ...myErrors, [key]: "كلمة سر غير مطابقة" };
    }
  }
  setformData({
    ...formData,
    errors: { ...formData.errors, ...myErrors },
    error: generalErr,
  });
  return Object.keys(myErrors).length !== 0 ? "failed" : "sucsess";
};
// handle remove empty key when submit any add form
export function removeEmptyValues(comingObject) {
  let obj = { ...comingObject };
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === "string" && value.trim() === "") {
      delete obj[key]; // Delete empty string values
    } else if (Array.isArray(value)) {
      obj[key] = value.filter(
        (item) => typeof item !== "string" || item.trim() !== ""
      );
      if (obj[key].length === 0) {
        delete obj[key]; // Delete arrays with only empty strings
      }
    } else if (typeof value === "object") {
      removeEmptyValues(value); // Recursively check nested objects
      if (Object.keys(value).length === 0) {
        delete obj[key]; // Delete objects with no remaining properties
      }
    }
  });
  return obj;
}
// handle combere between old data and new data to send only the changes to back-end in edit form
function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] != arr2[i]) {
      return false;
    }
  }

  return true;
}
export function compareObjects(newObject, oldData) {
  const diffObject = {};
  for (const key in newObject) {
    if (!(key in oldData) || newObject[key] != oldData[key]) {
      if (Array.isArray(newObject[key]) && Array.isArray(oldData[key])) {
        if (!arraysEqual(newObject[key], oldData[key])) {
          diffObject[key] = newObject[key];
        }
      } else {
        diffObject[key] = newObject[key];
      }
    }
  }
  return diffObject;
}
