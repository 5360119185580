import React from "react";

export default function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <ellipse
        cx="8.16667"
        cy="8.16667"
        rx="6.66667"
        ry="6.66667"
        stroke="#717171"
         strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 13.1315L16.9167 17.2983"
        stroke="#717171"
         strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
