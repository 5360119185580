import React from "react";
import PropTypes from "prop-types";

export default function HideIcon({stroke="white"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
    >
      <path
        d="M19.2994 8.21558L19.9575 7.85572L19.2994 8.21558ZM19.2994 9.78456L19.9574 10.1444L19.2994 9.78456ZM1.70058 8.21544L2.3586 8.57531L1.70058 8.21544ZM1.70057 9.78442L1.04254 10.1443H1.04254L1.70057 9.78442ZM18.0236 5.30907C17.727 5.0199 17.2522 5.0259 16.963 5.32247C16.6738 5.61904 16.6798 6.09387 16.9764 6.38304L18.0236 5.30907ZM8.18628 13.9596C7.78505 13.8567 7.37638 14.0986 7.2735 14.4998C7.17062 14.9011 7.41249 15.3097 7.81372 15.4126L8.18628 13.9596ZM13.104 3.34114L12.9099 4.06559L13.104 3.34114ZM5.57264 13.7089L5.20374 14.3619L6.00475 13.0959L5.57264 13.7089ZM2.3586 8.57531C3.93153 5.69919 6.9879 3.75 10.5 3.75V2.25C6.41882 2.25 2.86847 4.51686 1.04256 7.85557L2.3586 8.57531ZM18.6414 9.42469C17.0685 12.3008 14.0121 14.25 10.5 14.25V15.75C14.5812 15.75 18.1315 13.4831 19.9574 10.1444L18.6414 9.42469ZM18.6414 8.57544C18.7862 8.84022 18.7862 9.15992 18.6414 9.42469L19.9574 10.1444C20.3475 9.43118 20.3475 8.56898 19.9575 7.85572L18.6414 8.57544ZM1.04256 7.85557C0.652487 8.56882 0.65248 9.43102 1.04254 10.1443L2.3586 9.42456C2.2138 9.15979 2.2138 8.84008 2.3586 8.57531L1.04256 7.85557ZM16.9764 6.38304C17.6347 7.02488 18.1974 7.76347 18.6414 8.57544L19.9575 7.85572C19.4413 6.91179 18.7876 6.05403 18.0236 5.30907L16.9764 6.38304ZM10.5 14.25C9.70019 14.25 8.92506 14.1491 8.18628 13.9596L7.81372 15.4126C8.67323 15.633 9.57349 15.75 10.5 15.75V14.25ZM10.5 3.75C11.3346 3.75 12.1423 3.85991 12.9099 4.06559L13.2981 2.6167C12.405 2.37739 11.4668 2.25 10.5 2.25V3.75ZM4.42995 11.9904C3.59529 11.2678 2.89151 10.399 2.3586 9.42456L1.04254 10.1443C1.66202 11.2771 2.47944 12.2858 3.44816 13.1244L4.42995 11.9904ZM5.94154 13.0559C5.40181 12.751 4.8956 12.3935 4.42995 11.9904L3.44816 13.1244C3.98873 13.5924 4.57659 14.0076 5.20374 14.3619L5.94154 13.0559ZM3.50695 13.1704L5.14054 14.3219L6.00475 13.0959L4.37116 11.9444L3.50695 13.1704ZM12.9099 4.06559C13.6269 4.2577 14.3096 4.53359 14.9471 4.88219L15.6668 3.56615C14.926 3.161 14.1321 2.84016 13.2981 2.6167L12.9099 4.06559Z"
        fill={stroke}
      />
      <path
        d="M17.9648 1.46436L2.96484 16.4644"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9C7.5 7.34315 8.84315 6 10.5 6"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
HideIcon.propTypes = {
  stroke: PropTypes.string,
};
