import React from "react";

export default function ButtonArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
    >
      <path
        d="M1.00391 1L7.22296 6.33062C7.67234 6.7158 8.33547 6.7158 8.78486 6.33061L15.0039 1"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
