import React, { useState } from "react";
import PropTypes from "prop-types";
import agreeIcon from "../../../assets/icons/agree 1.png";
import disssAgreeIcon from "../../../assets/icons/disagree 1.png";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import SucsessMessage from "../../reuseables/SucsessMessage";
import SubPermesion from "./SubPermession";
export default function PermesionElement({
  permesion,
  getPermesions,
  permessionKey,
}) {
  const [loader, setloader] = useState("");
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleUpgrade = (permessionKey) => {
    setloader("loading");
    myFeach(
      "teachers/application-settings?data=permissions",
      "PUT",
      { [permessionKey]: { currentValue: !permesion.currentValue } },
      "token"
    )
      .then((data) => {
        console.log(data);
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: "تم تغير حالة الصلاحية بنجاح",
        });
        setloader("");
        setTimeout(() => {
          getPermesions();
        }, 1500);
      })
      .catch((err) => {
        let errText = parceErr(err).error
          ? parceErr(err).error
          : "فشل تغير حالة الصلاحية ";
        setopenFeedBackMessage({
          status: true,
          message: errText,
        });
        setloader("");
      });
  };
  function findObjects(obj) {
    let objectsArray = {};
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        let newObject = obj[key];
        for (const myKey in newObject) {
          if (
            typeof newObject[myKey] === "object" &&
            newObject[myKey] !== null
          ) {
            objectsArray = { ...objectsArray, [myKey]: newObject[myKey] };
          }
        }
      }
    }
    return Object.keys(objectsArray)?.length > 0 ? objectsArray : false;
  }
  return (
    <>
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <li className="  py-8 botom_border flex flex-wrap items-start justify-between">
        <div>
          <div className="flex flex-wrap items-center ">
            <div className="w-10 h-10 ml-3">
              <div
                className=" imge_bg"
                style={{
                  backgroundImage: `url(${permesion.currentValue ? agreeIcon : disssAgreeIcon})`,
                }}
              ></div>
            </div>
            <span> {permesion.arabicName}</span>
          </div>
          <div className="mr-10 mt-4">
            {findObjects(permesion) && (
              <>
                {Object.keys(findObjects(permesion))?.map((elem, index) => (
                  <SubPermesion
                    getPermesions={getPermesions}
                    key={index}
                    permesion={findObjects(permesion)[elem]}
                    handleUpgrade={handleUpgrade}
                    permessionKey={elem}
                  />
                ))}
              </>
            )}
          </div>
        </div>
        {permesion.mutable ? (
          <>
            <div className="flex flex-wrap items-center">
              <h6 className="font-normal md:mx-4 md:my-0 my-3 mx-0">
                {!permesion.currentValue
                  ? "إذا كنت ترغب في تفعيل هذه الخاصية"
                  : "إذا كنت ترغب في إلغاء تفعيل هذه الخاصية"}
              </h6>
              <button
                disabled={loader == "loading"}
                onClick={() => handleUpgrade(permessionKey)}
                className={` p-1 w-14 my_rounded bg_secondary  ${permesion.currentValue ? "" : "opacity-50"} `}
                style={{ borderRadius: "13px" }}
              >
                <div
                  className={`bg-white h-5 w-5 circle_rounded ${permesion.currentValue ? "mr-auto" : "ml-auto"}`}
                >
                  {loader === "loading" && <div className="loader"></div>}
                </div>
              </button>
            </div>
          </>
        ) : (
          <div className="flex flex-wrap items-center">
            <h6 className="font-normal md:mx-4 md:my-0 my-3 mx-0">
              تغير  وضع هذه الخاصية غير متاح لباقتك الحالية
            </h6>
            <a
              onClick={() => handleUpgrade(permesion.key)}
              href="https://wa.me/+201278279346"
              target="_blank"
              rel="noreferrer"
              className="stroke_btn  px-4 py-1 h-12 "
            >
              Upgrade
            </a>
          </div>
        )}
      </li>
    </>
  );
}
PermesionElement.propTypes = {
  permesion: PropTypes.object,
  getPermesions: PropTypes.func,
  permessionKey: PropTypes.string,
};
