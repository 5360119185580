import React from "react";
import PropTypes from "prop-types";
import DangerIcon from "../icons/DangerIcon";

export default function ResultMessage({ message, isModal = false }) {
  return (
    <div
      className={`w-full flex justify-center items-center ${isModal ? "my-4" : "mt-28"} flex-col`}
    >
      <div className="bg_danger p-2 flex items-center justify-center circle_rounded mb-2">
        <DangerIcon />
      </div>
      <h4 className="w-fit text-center">{message}</h4>
    </div>
  );
}
ResultMessage.propTypes = {
  message: PropTypes.string,
  isModal: PropTypes.bool,
};
