import React from "react";
import PropTypes from "prop-types";

export default function EscIcon({ type,stroke="white" }) {
  return (
    <>
      {type == "rounded" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <rect
            x="1"
            y="1"
            width="20"
            height="20"
            rx="5"
            stroke={stroke}
            strokeWidth="1.5"
          />
          <path
            d="M6.61531 15.382L15.3789 6.61841"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.61531 6.61848L15.3789 15.3821"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          className="esc_modal"
        >
          <path
            d="M21.5059 20.4512L21.8592 20.0979L21.5062 19.7444L13.773 12L21.5062 4.25564L21.8592 3.90209L21.5059 3.54879L20.4512 2.4941L20.0979 2.14081L19.7444 2.49385L12 10.227L4.25564 2.49385L3.90209 2.14081L3.54879 2.4941L2.4941 3.54879L2.14081 3.90209L2.49385 4.25564L10.227 12L2.49385 19.7444L2.14081 20.0979L2.4941 20.4512L3.54879 21.5059L3.90209 21.8592L4.25564 21.5062L12 13.773L19.7444 21.5062L20.0979 21.8592L20.4512 21.5059L21.5059 20.4512Z"
            fill="#555555"
            stroke="#555555"
          />
        </svg>
      )}
    </>
  );
}
EscIcon.propTypes = {
  type: PropTypes.string,
  stroke: PropTypes.string,
};
