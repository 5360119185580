import React, { useEffect, useState } from "react";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import Pagenation from "../../reuseables/Pagenation";
import ResultMessage from "../../reuseables/ResultMessage";
import CustomTable from "../courses/CustomTable";
import { convertToDate, cutString } from "../../../globalFunctions/TextModify";
import SelectFilter from "../../reuseables/SelectFilter";
import FilterListWithSearch from "../../reuseables/FilterListWithSearch";
export default function PrevRequests() {
  const [requests, setrequests] = useState({
    data: {},
    loader: { status: "loading" },
    numberOfPages: "",
    totalResult: "",
  });
  const [page, setpage] = useState(1);
  const [filters, setfilters] = useState({ status: "", student: "" });
  const handleGetRequests = () => {
    setrequests({ ...requests, loader: { status: "loading" } });
    let limit = 10;
    let apiUrl = `enrollment-requests?page=${page}&type=past&limit=${limit}${filters?.status ? `&status=${filters.status}` : ""}${filters?.student ? `&student=${filters.student}` : ""}`;
    myFeach(apiUrl, "GET", {}, "token")
      .then(({ data }) => {
        if (data.enrollmentRequests.length) {
          setrequests({
            loader: { status: "done" },
            data: data.enrollmentRequests,
            numberOfPages: roundNumber(data.totalResults, limit),
            totalResult: data.totalResults,
          });
        } else {
          setrequests({
            ...requests,
            loader: { status: "failed", text: "لا يوجد نتائج لعرضها" },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        let errText = parceErr(err)?.error
          ? parceErr(err)?.error
          : "فشل تحميل الطلبات السابقة";
        setrequests({ loader: { status: "failed", text: errText } });
      });
  };
  useEffect(() => {
    handleGetRequests();
  }, [page]);
  useEffect(() => {
    page == 1 ? handleGetRequests() : setpage(1);
  }, [filters]);
  return (
    <div>
      <div className="flex flex-wrap items-center mt-3 relative w-full md:w-4/5 m-auto justify-between ">
        <FilterListWithSearch
          apiPath="students?page=1&limit=5"
          width="300px"
          resultKey="students"
          onselect={(id) => setfilters({ ...filters, student: id })}
        />
        <SelectFilter
          type="select"
          message="حالة الطلب"
          list={[
            { value: "مقبول", name: "مقبول" },
            { value: "مرفوض", name: "مرفوض" },
          ]}
          loader={{ status: "done" }}
          width="150px"
          onchangeFun={(value) => setfilters({ ...filters, status: value })}
          containerClass="mt-6"
          marginLeft="0"
          activeChoice={filters?.status}
        />
      </div>
      {requests.loader.status == "done" && (
        <>
          <CustomTable headers={headers}>
            {requests.data.map((request, index) => (
              <tr key={index}>
                <td
                  className={` ${request.status == "مرفوض" ? "text-red-400" : "text-green-400"}`}
                >
                  {request.status}
                </td>
                <td className="relative " >
                  <div> {request.student.name}</div>
                  {request.student?.deletedAccount && (
                    <div className="text-[8px] text-white bg-red-500 absolute left-0 top-0 p-1 font-bold" style={{borderRadius:"0px 0px 12px 0px"}}> حساب محذوف </div>
                  )}
                </td>
                <td>{request.course.name}</td>
                <td>
                  {request.course?.sections.map((section, index) => (
                    <span key={index}>
                      {section.name}
                      {index + 1 < request.course.sections.length && ","}
                    </span>
                  ))}
                </td>
                <td>{request.price} ريال</td>
                <td>{request.paidAmount} ريال</td>
                <td>
                  {request.student?.enrollmentDate
                    ? convertToDate(request.student?.enrollmentDate)
                    : "----"}
                </td>
                <td>
                  {" "}
                  {request.teacherNote ? (
                    <>{cutString(request?.teacherNote, 50)}</>
                  ) : (
                    "-------"
                  )}{" "}
                </td>
              </tr>
            ))}
          </CustomTable>
          <Pagenation
            disabled={requests.loader.status == "loading" ? true : false}
            page={page}
            setPage={setpage}
            lastPage={requests.numberOfPages}
            totalResult={requests.totalResult}
            pageActualResult={requests.data?.length}
            name="طلب"
          />
        </>
      )}
      {requests.loader.status === "loading" && (
        <div className="w-full">
          <div className="my-3 bg-white h-14 w-full"></div>
          <div className="my-3 bg-white h-14 w-full"></div>
          <div className="my-3 bg-white h-14 w-full"></div>
          <div className="my-3 bg-white h-14 w-full"></div>
          <div className="my-3 bg-white h-14 w-full"></div>
          <div className="my-3 bg-white h-14 w-full"></div>
        </div>
      )}
      {requests.loader.status === "failed" && (
        <ResultMessage message={requests.loader.text} />
      )}
    </div>
  );
}
function roundNumber(number, limit) {
  const result = number / limit;
  if (result < 1) {
    return 1;
  } else if (result % 1 !== 0) {
    return Math.ceil(result);
  } else {
    return result;
  }
}
let headers = [
  { text: "حالة الطالب" },
  { text: "الاسم" },
  { text: "الكورس" },
  { text: "القسم" },
  { text: "السعر" },
  { text: "المبلغ" },
  { text: "تاريخ الاشتراك" },
  { text: "ملحوظة" },
];
