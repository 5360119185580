import React from "react";
import PropTypes from "prop-types";
export default function LoaderModal({ lable, loaderPrecentage }) {
  return (
    <>
      <div className="modal_bg h-screen"></div>
      <div className="modal_content  p-10 " style={{ position: "fixed" }}>
        {!loaderPrecentage && loaderPrecentage != 0 ? (
          <div className="scale-150">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="flex w-fit  items-center ">
            <div className=" h-4 my_rounded mt-4 md:w-60 w-36 bg_greay mx-2 ">
              <div
                className="bg_secondary my_rounded h-full"
                style={{ width: `${loaderPrecentage}%` }}
              ></div>
            </div>
            <div className="mt-3 w-16 text-center ">{loaderPrecentage} %</div>
          </div>
        )}
        <div className="mt-5 text-center">{lable}</div>
      </div>
    </>
  );
}
LoaderModal.propTypes = {
  lable: PropTypes.string,
  loaderPrecentage: PropTypes.any,
};
