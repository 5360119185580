import React from "react";
import PropTypes from "prop-types";

export default function DeleteIcon({stroke = "#EE392B"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
    >
      <path
        d="M2.20886 6.27848C1.50477 5.3397 2.17462 4 3.3481 4H12.6519C13.8254 4 14.4952 5.3397 13.7911 6.27848V6.27848C13.2776 6.9632 13 7.796 13 8.6519V14C13 15.8409 11.5076 17.3333 9.66667 17.3333H6.33333C4.49238 17.3333 3 15.8409 3 14V8.6519C3 7.796 2.7224 6.9632 2.20886 6.27848V6.27848Z"
        stroke={stroke}
        strokeWidth="1.25"
      />
      <path
        d="M9.66797 13.1667L9.66797 8.16669"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33203 13.1667L6.33203 8.16669"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3346 4L10.8812 2.63962C10.6543 1.95905 10.0174 1.5 9.30004 1.5H6.70257C5.98518 1.5 5.34829 1.95905 5.12143 2.63962L4.66797 4"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </svg>
  );
}
DeleteIcon.propTypes = {
  stroke: PropTypes.string
};
