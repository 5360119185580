import React, { useState } from "react";
import CurrentRequests from "../../components/pagesComponents/students/CurrentRequests";
import PrevRequests from "../../components/pagesComponents/students/PrevRequests";

export default function Requests() {
  const [pageFilter, setpageFilter] = useState("current");
  return (
    <main className="">
      <div className="flex  m-auto my_rounded overflow-hidden bg-white shadow-sm md:p-0 p-3 w-4/5">
        <button
          onClick={() => setpageFilter("current")}
          className={`${pageFilter === "current" ? "bg_secondary" : ""} px-6 py-2 w-1/2 `}
        >
          الطلبات الحالية
        </button>
        <button
          onClick={() => setpageFilter("prev")}
          className={`${pageFilter === "prev" ? "bg_secondary" : ""} px-6 py-2 w-1/2 `}
        >
          الطلبات السابقة
        </button>
      </div>
      {pageFilter == "current" && <CurrentRequests />}
      {pageFilter == "prev" && <PrevRequests />}
    </main>
  );
}
