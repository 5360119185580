import React from "react";

export default function RemoveBlockIcon() {
  return (
    <div className="p-2 my_rounded bg_secondary w-fit circle_rounded mx-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
      >
        <rect
          x="1"
          y="7"
          width="16"
          height="12"
          rx="4"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M9 14L9 12"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6667 3.14229C11.9035 3.48217 12.3709 3.56575 12.7108 3.32899C13.0507 3.09222 13.1343 2.62476 12.8975 2.28489L11.6667 3.14229ZM5.27819 2.04862C5.02082 2.37318 5.07528 2.84492 5.39984 3.10228C5.72439 3.35965 6.19613 3.30519 6.4535 2.98064L5.27819 2.04862ZM5.75 7V5H4.25V7H5.75ZM5.75 5C5.75 3.20508 7.20507 1.75 9 1.75V0.25C6.37665 0.25 4.25 2.37665 4.25 5H5.75ZM12.8975 2.28489C12.331 1.4717 11.5244 0.856063 10.5905 0.52421L10.0883 1.93762C10.7272 2.16467 11.2791 2.5859 11.6667 3.14229L12.8975 2.28489ZM10.5905 0.52421C9.65669 0.192356 8.64244 0.160906 7.68983 0.434263L8.10357 1.87607C8.75535 1.68904 9.44932 1.71056 10.0883 1.93762L10.5905 0.52421ZM7.68983 0.434263C6.73722 0.70762 5.89397 1.27209 5.27819 2.04862L6.4535 2.98064C6.87482 2.44933 7.45178 2.06311 8.10357 1.87607L7.68983 0.434263Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
