import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
export default function ProtectedRoute({ children }) {
  if (Cookies.get("token")) {
    return children;
  } else {
    return <Navigate to="/log-in/auth-problem" />;
  }
}
ProtectedRoute.propTypes = {
  children: PropTypes.any,
};
