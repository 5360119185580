import React from "react";
import PropTypes from "prop-types";

export default function DocumentIcon({ iconColor = "grediant" }) {
  return (
    <>
      {iconColor === "greay" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
        >
          <path
            d="M1.33203 4.00008C1.33203 2.15913 2.82442 0.666748 4.66536 0.666748H7.9987H9.71807C10.2127 0.666748 10.6818 0.886453 10.9984 1.26644L14.2791 5.20319C14.5287 5.50272 14.6654 5.88027 14.6654 6.27016V9.00008V14.0001C14.6654 15.841 13.173 17.3334 11.332 17.3334H4.66536C2.82441 17.3334 1.33203 15.841 1.33203 14.0001V4.00008Z"
            stroke="#717171"
            strokeWidth="1.25"
          />
          <path
            d="M10.5 1.0835V4.00016C10.5 4.92064 11.2462 5.66683 12.1667 5.66683H14.25"
            stroke="#717171"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
          <path
            d="M4.66406 9H11.3307"
            stroke="#717171"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
          <path
            d="M4.66406 13.1667H7.9974"
            stroke="#717171"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
        >
          <path
            d="M1.33594 4.00002C1.33594 2.15907 2.82832 0.666687 4.66927 0.666687H8.0026H9.72198C10.2166 0.666687 10.6857 0.886392 11.0023 1.26638L14.283 5.20313C14.5326 5.50265 14.6693 5.88021 14.6693 6.2701V9.00002V14C14.6693 15.841 13.1769 17.3334 11.3359 17.3334H4.66927C2.82832 17.3334 1.33594 15.841 1.33594 14V4.00002Z"
            stroke="url(#paint0_linear_4077_4241)"
            strokeWidth="1.25"
          />
          <path
            d="M10.5039 1.08334V4.00001C10.5039 4.92048 11.2501 5.66668 12.1706 5.66668H14.2539"
            stroke="url(#paint1_linear_4077_4241)"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
          <path
            d="M4.66797 9.00003H11.3346"
            stroke="url(#paint2_linear_4077_4241)"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
          <path
            d="M4.66797 13.1667H8.0013"
            stroke="url(#paint3_linear_4077_4241)"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4077_4241"
              x1="1.33592"
              y1="17.3333"
              x2="17.5961"
              y2="4.3252"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1D68DF" />
              <stop offset="1" stopColor="#23DFF8" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_4077_4241"
              x1="10.5039"
              y1="5.66667"
              x2="14.9965"
              y2="1.99093"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1D68DF" />
              <stop offset="1" stopColor="#23DFF8" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_4077_4241"
              x1="4.66796"
              y1="10"
              x2="4.96136"
              y2="8.04404"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1D68DF" />
              <stop offset="1" stopColor="#23DFF8" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_4077_4241"
              x1="4.66796"
              y1="14.1667"
              x2="5.21842"
              y2="12.3318"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1D68DF" />
              <stop offset="1" stopColor="#23DFF8" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </>
  );
}
DocumentIcon.propTypes = {
  iconColor: PropTypes.string,
};
