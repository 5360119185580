import React from "react";

export default function PersonsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_3363_2872)">
        <path
          d="M17.2999 18.6V21H0.5V18.6C0.5 18.6 0.5 13.8 8.89997 13.8C17.2999 13.8 17.2999 18.6 17.2999 18.6ZM13.1 7.20005C13.1 6.36937 12.8536 5.55735 12.3921 4.86666C11.9306 4.17598 11.2747 3.63766 10.5072 3.31977C9.73979 3.00188 8.89531 2.91871 8.08059 3.08076C7.26588 3.24282 6.51751 3.64283 5.93013 4.23021C5.34275 4.81759 4.94274 5.56595 4.78069 6.38067C4.61863 7.19539 4.7018 8.03987 5.01969 8.80731C5.33758 9.57476 5.8759 10.2307 6.56658 10.6922C7.25727 11.1537 8.06929 11.4 8.89997 11.4C10.0139 11.4 11.0822 10.9575 11.8698 10.1699C12.6575 9.38224 13.1 8.31395 13.1 7.20005ZM17.2279 13.8C17.9656 14.3709 18.5693 15.0965 18.9963 15.9258C19.4234 16.7551 19.6636 17.6679 19.6999 18.6V21H24.4999V18.6C24.4999 18.6 24.4999 14.244 17.2279 13.8ZM16.0999 3.00006C15.274 2.99546 14.4662 3.24241 13.784 3.70806C14.5129 4.72654 14.9048 5.9476 14.9048 7.20005C14.9048 8.4525 14.5129 9.67356 13.784 10.692C14.4662 11.1577 15.274 11.4046 16.0999 11.4C17.2138 11.4 18.2821 10.9575 19.0698 10.1699C19.8574 9.38224 20.2999 8.31395 20.2999 7.20005C20.2999 6.08614 19.8574 5.01786 19.0698 4.23021C18.2821 3.44256 17.2138 3.00006 16.0999 3.00006Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3363_2872">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
