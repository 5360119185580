import React from "react";

export default function SettingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_3363_2891"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path
          d="M9.142 21.5856C7.48803 21.0917 5.99009 20.1781 4.794 18.9336C5.17884 18.4771 5.41874 17.9162 5.483 17.3227C5.54726 16.7291 5.43296 16.1299 5.15472 15.6017C4.87649 15.0735 4.44698 14.6403 3.92115 14.3575C3.39531 14.0748 2.79708 13.9554 2.203 14.0146C2.06766 13.3516 1.99965 12.6767 2 12.0001C2 10.9551 2.16 9.94707 2.458 9.00007H2.5C3.00988 9.00024 3.51139 8.87044 3.95717 8.62295C4.40295 8.37545 4.7783 8.01842 5.04777 7.58557C5.31724 7.15271 5.47194 6.65832 5.49726 6.14907C5.52259 5.63982 5.4177 5.13252 5.1925 4.67507C6.3648 3.58348 7.78253 2.78999 9.326 2.36157C9.57719 2.85477 9.95992 3.26887 10.4319 3.55805C10.9038 3.84723 11.4465 4.00021 12 4.00007C12.5535 4.00021 13.0962 3.84723 13.5681 3.55805C14.0401 3.26887 14.4228 2.85477 14.674 2.36157C16.2175 2.78999 17.6352 3.58348 18.8075 4.67507C18.5807 5.13573 18.4759 5.6469 18.5031 6.15965C18.5304 6.6724 18.6888 7.16958 18.9632 7.60359C19.2376 8.03759 19.6188 8.3939 20.0703 8.63842C20.5219 8.88293 21.0286 9.00745 21.542 9.00007C21.8465 9.97093 22.0009 10.9826 22 12.0001C22 12.6901 21.93 13.3641 21.797 14.0151C21.2029 13.9559 20.6047 14.0753 20.0789 14.358C19.553 14.6408 19.1235 15.074 18.8453 15.6022C18.567 16.1304 18.4527 16.7296 18.517 17.3232C18.5813 17.9168 18.8212 18.4776 19.206 18.9341C18.0099 20.1784 16.5119 21.0919 14.858 21.5856C14.6638 20.9802 14.2824 20.4522 13.7687 20.0775C13.2551 19.7029 12.6357 19.501 12 19.501C11.3643 19.501 10.7449 19.7029 10.2313 20.0775C9.71765 20.4522 9.33623 20.9802 9.142 21.5856Z"
          fill="white"
        />
        <path
          d="M12 15.5C12.4596 15.5 12.9148 15.4095 13.3394 15.2336C13.764 15.0577 14.1499 14.7999 14.4749 14.4749C14.7999 14.1499 15.0577 13.764 15.2336 13.3394C15.4095 12.9148 15.5 12.4596 15.5 12C15.5 11.5404 15.4095 11.0852 15.2336 10.6606C15.0577 10.236 14.7999 9.85013 14.4749 9.52513C14.1499 9.20012 13.764 8.94231 13.3394 8.76642C12.9148 8.59053 12.4596 8.5 12 8.5C11.0717 8.5 10.1815 8.86875 9.52513 9.52513C8.86875 10.1815 8.5 11.0717 8.5 12C8.5 12.9283 8.86875 13.8185 9.52513 14.4749C10.1815 15.1313 11.0717 15.5 12 15.5Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_3363_2891)">
        <path d="M0 0H24V24H0V0Z" fill="white" />
      </g>
    </svg>
  );
}
