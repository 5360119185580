import React from "react";

export default function RightArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_566_3759)">
        <path
          d="M5.29279 1.29279C5.10532 1.48031 5 1.73462 5 1.99979C5 2.26495 5.10532 2.51926 5.29279 2.70679L10.2428 7.65679L5.29279 12.6068C5.11063 12.7954 5.00983 13.048 5.01211 13.3102C5.01439 13.5724 5.11956 13.8232 5.30497 14.0086C5.49037 14.194 5.74119 14.2992 6.00338 14.3015C6.26558 14.3037 6.51818 14.2029 6.70678 14.0208L12.3638 8.36379C12.5513 8.17626 12.6566 7.92195 12.6566 7.65679C12.6566 7.39162 12.5513 7.13731 12.3638 6.94979L6.70679 1.29279C6.51926 1.10532 6.26495 1 5.99979 1C5.73462 1 5.48031 1.10532 5.29279 1.29279Z"
          fill="#4271FF"
          stroke="#4271FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_566_3759">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
