import React from "react";
import PropTypes from "prop-types";

export default function CoursesIcon({ decoration = "none", color = "white" }) {
  return (
    <>
      {decoration == "line_throw" ? (
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M41.344 22.2303L46.303 24.9129L47.3412 25.4962C49.5529 26.7388 49.5529 29.9425 47.3412 31.1851L33.2557 39.0988C29.989 40.9341 26.011 40.9341 22.7443 39.0988L8.65882 31.1851C6.44706 29.9425 6.44706 26.7388 8.65883 25.4962L9.69698 24.9129L14.3811 22.2303M42.4225 34.1742L46.9301 36.4433C49.2548 37.6135 49.3295 40.9261 47.0599 42.2013L33.2557 49.9569C29.989 51.7922 26.011 51.7922 22.7443 49.9569L9.14153 42.3144C6.83292 41.0174 6.96013 37.6315 9.35946 36.5137L14.3811 34.1742M33.2557 27.5594L47.3412 19.6458C49.5529 18.4031 49.553 15.1995 47.3412 13.9568L33.2557 6.04319C29.989 4.20785 26.011 4.20785 22.7443 6.04319L8.65883 13.9568C6.44706 15.1995 6.44706 18.4031 8.65882 19.6458L22.7443 27.5594C26.011 29.3947 29.989 29.3948 33.2557 27.5594Z"
              stroke="#717171"
              strokeWidth="3.5"
            />
          </svg>
          <svg
            className="absolute right-0 top-0"
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
          >
            <path d="M58 2L2 58" stroke="#4D4D4D" strokeWidth="4.66667" />
          </svg>
        </div>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17.7189 9.52728L19.8442 10.677L20.2891 10.9269C21.237 11.4595 21.237 12.8325 20.2891 13.365L14.2525 16.7566C12.8524 17.5432 11.1476 17.5432 9.74754 16.7566L3.71092 13.365C2.76302 12.8325 2.76303 11.4595 3.71093 10.9269L4.15585 10.677L6.16334 9.52728M18.1811 14.6461L20.1129 15.6185C21.1092 16.1201 21.1412 17.5398 20.1685 18.0862L14.2525 21.4101C12.8524 22.1966 11.1476 22.1966 9.74754 21.4101L3.9178 18.1347C2.9284 17.5789 2.98291 16.1278 4.0112 15.6487L6.16334 14.6461M14.2525 11.8112L20.2891 8.41961C21.237 7.88705 21.237 6.51405 20.2891 5.98149L14.2525 2.58993C12.8524 1.80336 11.1476 1.80336 9.74754 2.58993L3.71093 5.98149C2.76303 6.51405 2.76302 7.88705 3.71092 8.41961L9.74754 11.8112C11.1476 12.5977 12.8524 12.5977 14.2525 11.8112Z"
            stroke={color}
            strokeWidth="1.5"
          />
        </svg>
      )}
    </>
  );
}
CoursesIcon.propTypes = {
  decoration: PropTypes.string,
  color: PropTypes.string,
};
