import React from "react";

export default function PromoCodeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
    >
      <g clipPath="url(#clip0_3962_30097)">
        <path
          d="M-0.00390625 11.0002L-0.00390625 18.3336H1.82943C2.80189 18.3336 3.73452 18.7199 4.42215 19.4075C5.10979 20.0952 5.49609 21.0278 5.49609 22.0002C5.49609 22.9727 5.10979 23.9053 4.42215 24.593C3.73452 25.2806 2.80189 25.6669 1.82943 25.6669H-0.00390625L-0.00390625 33.0002C-0.00390625 34.4589 0.575556 35.8579 1.60701 36.8893C2.63846 37.9208 4.0374 38.5002 5.49609 38.5002H43.9961V25.6669L42.1628 25.6669C41.1903 25.6669 40.2577 25.2806 39.57 24.593C38.8824 23.9053 38.4961 22.9727 38.4961 22.0002C38.4961 21.0278 38.8824 20.0952 39.57 19.4075C40.2577 18.7199 41.1903 18.3336 42.1628 18.3336H43.9961V5.50024H5.49609C4.0374 5.50024 2.63846 6.0797 1.60701 7.11115C0.575556 8.1426 -0.00390625 9.54155 -0.00390625 11.0002ZM40.3294 14.8979C38.7536 15.3031 37.3573 16.2208 36.3603 17.5066C35.3633 18.7923 34.8222 20.3732 34.8222 22.0002C34.8222 23.6273 35.3633 25.2081 36.3603 26.4939C37.3573 27.7797 38.7536 28.6974 40.3294 29.1026V34.8336H31.1628V29.3336H27.4961V34.8336H5.49609C5.00986 34.8336 4.54355 34.6404 4.19973 34.2966C3.85591 33.9528 3.66276 33.4865 3.66276 33.0002V29.1026C5.23856 28.6974 6.63487 27.7797 7.63188 26.4939C8.6289 25.2081 9.16999 23.6273 9.16999 22.0002C9.16999 20.3732 8.6289 18.7923 7.63188 17.5066C6.63487 16.2208 5.23856 15.3031 3.66276 14.8979L3.66276 11.0002C3.66276 10.514 3.85591 10.0477 4.19973 9.70388C4.54355 9.36006 5.00986 9.16691 5.49609 9.16691H27.4961L27.4961 14.6669L31.1628 14.6669L31.1628 9.16691H40.3294V14.8979Z"
          fill="white"
        />
        <path
          d="M27.4961 20.167V23.8337C27.4961 24.8462 28.3169 25.667 29.3294 25.667C30.3419 25.667 31.1628 24.8462 31.1628 23.8337V20.167C31.1628 19.1545 30.3419 18.3337 29.3294 18.3337C28.3169 18.3337 27.4961 19.1545 27.4961 20.167Z"
          fill="white"
        />
        <circle cx="14.3533" cy="19.0356" r="1.56028" fill="white" />
        <path
          d="M19.4992 17.8714L13.1885 24.1821C12.6249 24.7457 12.6249 25.6596 13.1885 26.2232C13.7522 26.7869 14.666 26.7869 15.2297 26.2232L21.5403 19.9126C22.104 19.3489 22.104 18.4351 21.5403 17.8714C20.9767 17.3078 20.0628 17.3078 19.4992 17.8714Z"
          fill="white"
        />
        <circle cx="20.437" cy="25.4326" r="1.71631" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_3962_30097">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="matrix(0 -1 1 0 0 44)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
