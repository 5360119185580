import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  checkRequired,
  compareObjects,
  removeEmptyValues,
} from "../../globalFunctions/validation";
import SucsessMessage from "../../components/reuseables/SucsessMessage";
import CustomInput from "../../components/reuseables/CustomInput";
import CustomUplodeVideo from "../../components/reuseables/CustomUplodeVideo";
import CustomUplodeDoc from "../../components/reuseables/CustomUplodeDoc";
import CustomSubmitButton from "../../components/reuseables/CustomSubmitButton";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import ResultMessage from "../../components/reuseables/ResultMessage";

export default function AddLeson() {
  const prams = useParams();
  const [lesonOldData, setlesonOldData] = useState();
  const [formData, setformData] = useState({
    data: { section: prams.section_id },
    submitLoader: { status: "" },
    getOldDataLoader: {
      status: window.location.pathname.includes("edit") ? "loading" : "done",
    },
    errors: {},
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleGetOldLesonData = () => {
    setformData({ ...formData, getOldDataLoader: { status: "loading" } });
    myFeach(`lessons/${prams.leson_id}`, "GET", {}, "token")
      .then(({ data }) => {
        setformData({
          ...formData,
          getOldDataLoader: { status: "done" },
          data: data.lesson,
        });
        setlesonOldData(data.lesson);
      })
      .catch((err) => {
        setformData({
          ...formData,
          getOldDataLoader: {
            status: "failed",
            text: parceErr(err).error
              ? parceErr(err).error
              : "فشل الحصول على معلومات الدرس الحالية",
          },
        });
      });
  };
  const handleAddLeson = () => {
    let requiredErrs = checkRequired(
      formData.data,
      ["name"],
      formData,
      setformData
    );
    if (requiredErrs === "sucsess") {
      setformData({
        ...formData,
        submitLoader: { status: "loading" },
        errors: {},
        error: "",
      });
      myFeach("lessons", "POST", removeEmptyValues(formData.data), "token")
        .then((data) => {
          console.log(data);
          setformData({
            ...formData,
            submitLoader: { status: "done" },
            error: "",
            errors: {},
            data: {
              name: "",
              video: "",
              section: prams.section_id,
              pdf: "",
              description: "",
            },
          });
          setopenFeedBackMessage({
            status: true,
            isSucsess: true,
            message: "تم إضافة الدرس بنجاح",
          });
        })
        .catch((err) => {
          setformData({
            ...formData,
            submitLoader: { status: "failed" },
            errors: parceErr(err)?.errors,
            error: parceErr(err)?.error
              ? parceErr(err)?.error
              : "فشل رفع الدرس",
          });
          setopenFeedBackMessage({
            status: true,
            message: parceErr(err)?.error
              ? parceErr(err)?.error
              : "فشل رفع الدرس",
          });
        });
    }
  };
  const handleEditLesson = () => {
    setformData({
      ...formData,
      submitLoader: {
        status: "loading",
        error: "",
        errors: {},
        data: { ...formData.data },
      },
    });
    const finalObject = compareObjects(formData.data, lesonOldData);
    console.log("final object to send:", finalObject);
    myFeach(`lessons/${prams.leson_id}`, "PUT", finalObject, "token")
      .then((data) => {
        console.log(data);
        setformData({
          ...formData,
          submitLoader: { status: "done" },
          error: "",
          errors: {},
        });
        setlesonOldData(formData.data);
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: "تم تعديل الدرس بنجاح",
        });
      })
      .catch((err) => {
        console.log(err);
        setopenFeedBackMessage({
          status: true,
          message: parceErr(err)?.error
            ? parceErr(err).error
            : "فشل تعديل الدرس حاول مرة أخرى",
        });
        setformData({
          ...formData,
          submitLoader: { status: "done" },
          error: parceErr(err)?.error
            ? parceErr(err).error
            : "فشل تعديل الدرس حاول مرة أخرى",
          errors: parceErr(err).errors,
        });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (window.location.pathname.includes("edit")) {
      handleEditLesson();
    } else {
      handleAddLeson();
    }
  };
  useEffect(() => {
    window.location.pathname.includes("edit") && handleGetOldLesonData();
  }, []);
  return (
    <main>
      {openFeedBackMessage?.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      {window.location.pathname.includes("edit") ? (
        <h2 className="font-bold text-3xl">تعديل الدرس</h2>
      ) : (
        <h2 className="font-bold text-3xl">إضافة درس جديد</h2>
      )}
      {formData.getOldDataLoader?.status === "failed" ? (
        <ResultMessage message={formData.getOldDataLoader?.text} />
      ) : (
        <form className="mt-10 md:w-10/12 " onSubmit={handleSubmit}>
          <div className="flex flex-wrap justify-between my-8 ">
            <CustomInput
              width="100%"
              lable={"اسم الكورس"}
              err={false}
              value={prams.course_name}
              disabled={true}
              containerClass="w-full md:w-7/12 md:pl-6"
              styleClass="font-bold"
            />
            <CustomInput
              width="100%"
              lable={"اسم القسم"}
              value={prams.section_name}
              name="section"
              disabled={true}
              containerClass="w-full md:w-5/12"
              styleClass="font-bold"
            />
          </div>
          <div className="flex justify-between flex-wrap">
            <CustomUplodeVideo
              name="video"
              formData={formData}
              setformData={setformData}
              // disabled={formData.submitLoader.status === "loading" ? true : false}
              oldVideo={
                lesonOldData?.video ? lesonOldData.video : "no video yet"
              }
              disabled={false}
              onChange={(url) =>
                setformData({
                  ...formData,
                  data: { ...formData.data, video: url },
                })
              }
              lable="الفيديو"
              btn_class="px-24 py-16"
              // required={true}
              containerClass=""
              getOldDataLoader={formData.getOldDataLoader?.status}
              courseId={prams.course_id}
            />
            <CustomUplodeDoc
              name="pdf"
              formData={formData}
              apiUrl="lessonPdf"
              setformData={setformData}
              disabled={
                formData.submitLoader?.status === "loading" ? true : false
              }
              lable="المستند"
              btn_class="px-24 py-16"
              // required={true}
              containerClass="md:w-5/12 w-full"
              getOldDataLoader={formData.getOldDataLoader?.status}
            />
          </div>
          <CustomInput
            width="100%"
            lable={"اسم الدرس"}
            err={false}
            required={true}
            type=""
            name="name"
            setformData={setformData}
            formData={formData}
            containerClass="w-full"
            getOldDataLoader={formData.getOldDataLoader?.status}
          />
          <CustomInput
            width="100%"
            height="120px"
            lable={"وصف الدرس"}
            err={false}
            type="textArea"
            name="description"
            setformData={setformData}
            formData={formData}
            containerClass="w-full my-8"
            getOldDataLoader={formData.getOldDataLoader?.status}
          />
          <CustomSubmitButton
            loader={formData.submitLoader}
            err={formData?.error}
            disabled={
              formData.getOldDataLoader?.status !== "done" ? true : false
            }
            width="100%"
            height="47px"
            title={
              !window.location.pathname.includes("edit")
                ? "تأكيد الإضافة"
                : "تعديل الدرس"
            }
          />
        </form>
      )}
    </main>
  );
}
