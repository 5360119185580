import React from "react";

export default function DolarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect
        x="1.66602"
        y="1.61972"
        width="26.6667"
        height="26.6667"
        rx="7.11111"
        stroke="#1D68DF"
        strokeWidth="2"
      />
      <path
        d="M19.9085 18.4617C19.9085 19.1738 19.7483 19.8563 19.4278 20.5092C19.1073 21.1471 18.6333 21.6813 18.0057 22.1115C17.3915 22.5418 16.6504 22.7866 15.7824 22.8459V24.6486H14.7008V22.8459C13.4857 22.7272 12.4976 22.297 11.7364 21.5551C10.9753 20.7985 10.5881 19.8415 10.5747 18.6842H12.5176C12.571 19.3074 12.778 19.8489 13.1385 20.3089C13.5124 20.7688 14.0332 21.0581 14.7008 21.1768V15.613C13.8062 15.3608 13.0851 15.1012 12.5376 14.8341C11.9901 14.567 11.5228 14.1516 11.1355 13.5878C10.7483 13.024 10.5547 12.2673 10.5547 11.3178C10.5547 10.116 10.9286 9.12194 11.6763 8.33559C12.4375 7.54924 13.4456 7.11155 14.7008 7.02253V5.17536H15.7824V7.02253C16.9174 7.12639 17.8321 7.5344 18.5265 8.24657C19.2208 8.9439 19.6214 9.85636 19.7283 10.984H17.7854C17.7186 10.4647 17.5116 9.99731 17.1645 9.58188C16.8173 9.15161 16.3566 8.86971 15.7824 8.73618V14.1664C16.6637 14.4187 17.3781 14.6783 17.9256 14.9454C18.4864 15.1976 18.9538 15.6056 19.3277 16.1694C19.7149 16.7332 19.9085 17.4973 19.9085 18.4617ZM12.4174 11.2065C12.4174 11.9335 12.6111 12.4899 12.9983 12.8756C13.3855 13.2614 13.953 13.5804 14.7008 13.8326V8.69167C14.0065 8.76585 13.4523 9.01808 13.0384 9.44835C12.6244 9.86377 12.4174 10.4498 12.4174 11.2065ZM15.7824 21.1991C16.5035 21.11 17.0643 20.8207 17.4649 20.3311C17.8789 19.8415 18.0858 19.2554 18.0858 18.573C18.0858 17.846 17.8855 17.2896 17.4849 16.9038C17.0843 16.5032 16.5168 16.1842 15.7824 15.9468V21.1991Z"
        fill="#1D68DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9991 4.28647C15.4901 4.28647 15.888 4.68444 15.888 5.17536V24.7309C15.888 25.2218 15.4901 25.6198 14.9991 25.6198C14.5082 25.6198 14.1102 25.2218 14.1102 24.7309V5.17536C14.1102 4.68444 14.5082 4.28647 14.9991 4.28647Z"
        fill="#1D68DF"
      />
    </svg>
  );
}
