import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ImageIcon from "../icons/ImageIcon";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import CustomImage from "./CustomImage";
import InputScaleable from "./InputScaleable";
import LoaderModal from "./LoaderModal";
export default function CustomUploadeInput({
  btn_class,
  lable,
  required,
  disabled,
  formData,
  setformData,
  name = "",
  apiUrl,
  getOldDataLoader = "done",
}) {
  const [loader, setloader] = useState();
  const [uplodeErr, setuplodeErr] = useState();
  const inputRef = useRef(null);
  const uplodeImage = async (e) => {
    let file = await e.target.files[0];
    if (validatImage(file)) {
      setloader("loading");
      setuplodeErr("");
      let Imageformdata = new FormData();
      Imageformdata.append("file", file);
      myFeach(
        `upload-file/?type=${apiUrl}`,
        "POST",
        Imageformdata,
        "token",
        true
      )
        .then(({ data }) => {
          inputRef.current.value = "";
          setloader(false);
          setuplodeErr("");
          setformData({
            ...formData,
            data: { ...formData.data, [name]: data.file },
            errors: { ...formData.errors, [name]: "" },
          });
        })
        .catch((err) => {
          console.log(err);
          inputRef.current.value = "";
          setloader("failed");
          setuplodeErr(
            parceErr(err)?.error
              ? parceErr(err).error
              : "فشل رفع الصورة حاول مرة أخرى"
          );
        });
    } else {
      setuplodeErr("مسموح برفع الملف بصيغة صورة فقط");
    }
  };
  return (
    <>
      {loader === "loading" && <LoaderModal lable="جاري رفع الصورة" />}
      {getOldDataLoader === "done" ? (
        <div className="mb-10 ">
          <label className="mb-5 block font-bold text-sm ">
            {lable} {required && <span className="text_danger">*</span>}{" "}
          </label>
          <div className="flex items-center  ">
            <button
              disabled={disabled}
              type="button"
              onClick={() => inputRef.current.click()}
              className={`w-14 h-14 my_rounded flex items-center justify-center ml-4 bg-white ${btn_class} ${formData?.errors[name] ? "danger_border" : ""}`}
            >
              {loader === "loading" ? (
                <div className="loader"></div>
              ) : (
                <>
                  {formData?.data[name] ? (
                    <CustomImage src={formData?.data[name]} />
                  ) : (
                    <ImageIcon />
                  )}
                </>
              )}
            </button>
            <h4>يمكنك رفع ملفات بصيغة (PNG , JPEG)</h4>
            <input
              ref={inputRef}
              disabled={disabled}
              type="file"
              className="display_none"
              onChange={uplodeImage}
            />
          </div>
          <h4 className="text-xs text_danger mt-2">{formData?.errors[name]}</h4>
          <h4 className="text-xs text_danger mt-2">{uplodeErr}</h4>
        </div>
      ) : (
        <InputScaleable width="70%" height="40px" />
      )}
    </>
  );
}
CustomUploadeInput.propTypes = {
  lable: PropTypes.string,
  apiUrl: PropTypes.string,
  btn_class: PropTypes.string,
  name: PropTypes.string,
  getOldDataLoader: PropTypes.string,
  formData: PropTypes.object,
  setformData: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
let validatImage = (file) => {
  let flag = "";
  const allowedTypes = [
    "image/apng",
    "image/bmp",
    // "image/gif",
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
  ];
  if (allowedTypes.includes(file.type)) {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};
