import React from "react";

export default function StarageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <rect
        x="2.2207"
        y="2.06418"
        width="15.5556"
        height="4.44444"
        rx="2.22222"
        stroke="#1D68DF"
        strokeWidth="1.11111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.44336 4.28641H7.77669"
        stroke="#1D68DF"
        strokeWidth="1.11111"
        strokeLinecap="round"
      />
      <rect
        x="2.2207"
        y="8.73083"
        width="15.5556"
        height="4.44444"
        rx="2.22222"
        stroke="#1D68DF"
        strokeWidth="1.11111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.44336 10.9531H7.77669"
        stroke="#1D68DF"
        strokeWidth="1.11111"
        strokeLinecap="round"
      />
      <rect
        x="2.2207"
        y="15.3975"
        width="15.5556"
        height="4.44444"
        rx="2.22222"
        stroke="#1D68DF"
        strokeWidth="1.11111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.44336 17.6198H7.77669"
        stroke="#1D68DF"
        strokeWidth="1.11111"
        strokeLinecap="round"
      />
    </svg>
  );
}
