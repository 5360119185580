import React from "react";
import PropTypes from "prop-types";

export default function VideoIcon({ iconColor = "grediant" }) {
  return (
    <>
      {
        (iconColor == "greay" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M14.1719 3.33325H5.83854C3.53736 3.33325 1.67188 5.19873 1.67188 7.49992V12.4999C1.67188 14.8011 3.53736 16.6666 5.83854 16.6666H14.1719C16.4731 16.6666 18.3385 14.8011 18.3385 12.4999V7.49992C18.3385 5.19873 16.4731 3.33325 14.1719 3.33325Z"
              stroke="#717171"
              strokeWidth="1.25"
            />
            <path
              d="M13.3359 10.0001L8.33594 13.3334V6.66675L13.3359 10.0001Z"
              stroke="#717171"
              strokeWidth="1.25"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M14.1719 3.33331H5.83854C3.53736 3.33331 1.67188 5.19879 1.67188 7.49998V12.5C1.67188 14.8012 3.53736 16.6666 5.83854 16.6666H14.1719C16.4731 16.6666 18.3385 14.8012 18.3385 12.5V7.49998C18.3385 5.19879 16.4731 3.33331 14.1719 3.33331Z"
              stroke="url(#paint0_linear_3962_18902)"
              strokeWidth="1.25"
            />
            <path
              d="M13.3359 9.99999L8.33594 13.3333V6.66666L13.3359 9.99999Z"
              stroke="url(#paint1_linear_3962_18902)"
              strokeWidth="1.25"
              strokeLinejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3962_18902"
                x1="1.67186"
                y1="16.6666"
                x2="14.68"
                y2="0.406467"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1D68DF" />
                <stop offset="1" stopColor="#23DFF8" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_3962_18902"
                x1="8.33593"
                y1="13.3333"
                x2="14.7359"
                y2="8.53332"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1D68DF" />
                <stop offset="1" stopColor="#23DFF8" />
              </linearGradient>
            </defs>
          </svg>
        ))
      }
    </>
  );
}
VideoIcon.propTypes = {
  iconColor: PropTypes.string,
};
