import React, { useEffect } from "react";
import PropTypes from "prop-types";
import RightIcon from "../icons/RightIcon";
import DangerIcon from "../icons/DangerIcon";
import EscIcon from "../icons/EscIcon";

export default function SucsessMessage({ close, message, isSucsess }) {
  useEffect(() => {
    setTimeout(() => {
      close();
    }, 2000);
  }, []);
  return (
    <>
      {/* <button onClick={close} className="modal_bg index_1000"></button> */}
      <div className="modal_massage_content   bg-ehite p-8 ">
        <div className="flex w-full items-start ">
          {isSucsess ? (
            <div className="bg_success circle_rounded flex items-center justify-center w-8 h-8 ml-2 ">
              <RightIcon />
            </div>
          ) : (
            <div className="bg_danger circle_rounded w-9 h-9 ml-2 flex items-center justify-center scale-50 md:scale-100 ">
              <DangerIcon />
            </div>
          )}
          <h3
            className={`md:ml-36 font-bold text-xs md:text-lg  ${isSucsess ? "text_sucsess" : "text_danger"} `}
          >
            {message}
          </h3>
          <button onClick={close} className="scale-50 md:scale-100" >
            <EscIcon type="rounded" stroke="#4D4D4D" />
          </button>
        </div>
      </div>
    </>
  );
}
SucsessMessage.propTypes = {
  close: PropTypes.func,
  message: PropTypes.string,
  isSucsess: PropTypes.bool,
};
