import React from "react";
import PropTypes from "prop-types";
import RedWorning from "../reuseables/RedWorning";

export default function CompleteDataWorning({ profileWorning, appWoorning }) {
  const handleMessage = () => {
    let message = "";
    if (profileWorning && !appWoorning) {
      message = "من فضلك قم باستكمال بياناتك الشخصية للبدء في استخدام النظام";
    }
    if (!profileWorning && appWoorning) {
      message =
        "من فضلك قم باستكمال بيانات تطبيق الطالب للبدء في استخدام النظام ";
    }
    if (profileWorning && appWoorning) {
      message =
        "من فضلك قم باستكمال بياناتك الشخصية وبيانات تطبيق الطالب للبدء في استخدام النظام";
    }
    return message;
  };
  return (
    <div className="bg_greay pt-4 warning" id="warning">
      <div className=" bg-red-100   py-10 px-5 my_rounded w-full m-auto ">
        <div className="flex items-center">
          <RedWorning />
          <h4 className="mx-2 text_danger">{handleMessage()}</h4>
        </div>
        <h5 className="mt-2">
          من خلال صفحة الإعدادات يمكنك استكمال أو تعديل بياناتك الشخصية أو
          بيانات تطبيق الطالب أو الصلاحيات
        </h5>
      </div>
    </div>
  );
}
CompleteDataWorning.propTypes = {
  profileWorning: PropTypes.string,
  appWoorning: PropTypes.string,
};
