import React, { useState } from "react";
import PropTypes from "prop-types";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import EscIcon from "../../icons/EscIcon";
import CustomInput from "../../reuseables/CustomInput";
import SucsessMessage from "../../reuseables/SucsessMessage";
import { checkRequired } from "../../../globalFunctions/validation";
export default function ReqDetailesModal({ close, req, regetRequestis }) {
  const [reqDetailes] = useState({
    data: { ...req },
  });
  const [formData, setformData] = useState({
    data: {},
    submitLoader: { status: "" },
    errors: {},
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
    message: "",
  });
  const handleChangeReqStatus = (type) => {
    let requiredErrs =
      type == "مقبول"
        ? checkRequired(formData.data, ["paidAmount"], formData, setformData)
        : "sucsess";
    if (requiredErrs === "sucsess") {
      let url = `enrollment-requests/${reqDetailes.data.id}`;
      setformData({
        ...formData,
        errors: {},
        error: "",
        submitLoader: { status: "loading", type: type },
      });
      myFeach(url, "PUT", { ...formData.data, status: type }, "token")
        .then(({ data }) => {
          console.log(data);
          setformData({
            ...formData,
            errors: {},
            error: "",
            submitLoader: { status: "done" },
          });
          setopenFeedBackMessage({
            status: true,
            isSucsess: true,
            message: "تم تغير الحالة بنجاح",
          });
          setTimeout(() => {
            regetRequestis();
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          let textrr = parceErr(err)?.error
            ? parceErr(err)?.error
            : "فشل تغير حالة الطلب";
          setformData({
            ...formData,
            submitLoader: { status: "failed" },
            error: textrr,
            errors: parceErr(err)?.errors,
          });
          setopenFeedBackMessage({ status: true, message: textrr });
        });
    }
  };
  return (
    <>
      {openFeedBackMessage?.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}

      <div>
        <button onClick={close} className="modal_bg"></button>
        <div className="modal_content  w-11/12 md:w-5/12">
          <div className="relative  bg_primary  modal_title">
            <div className="w-fit m-auto py-3 text_white">
              <h3 className="text-white text-center">
                {reqDetailes.data.student.name}
              </h3>
              <h3 className="text-white text-center ">
                {reqDetailes.data.student.phone}
              </h3>
            </div>
            <button
              type="button"
              className="absolute left-4 top-4 "
              onClick={close}
            >
              <EscIcon type="rounded" />
            </button>
          </div>

          <div className="overflow-y-scroll">
            <div className="w-1/3 my-8 mx-auto ">
              {reqDetailes.data?.proofOfPayment ? (
                <img
                  className="block w-full h-auto"
                  src={reqDetailes.data?.proofOfPayment}
                />
              ) : (
                <h6 className="text-center w-fit mx-auto mt-2 mb-2 text_danger">
                  لا يوجد إثبات دفع
                </h6>
              )}
            </div>
            <form className="p-5">
              <CustomInput
                width="100%"
                height="40px"
                lable={"المبلع المدفوع"}
                err={false}
                type="number"
                required={true}
                name="paidAmount"
                setformData={setformData}
                formData={formData}
              />
              <CustomInput
                width="100%"
                height="80px"
                lable={"ملحوظة"}
                err={false}
                type="textArea"
                name="teacherNote"
                setformData={setformData}
                formData={formData}
                containerClass="my-4"
              />
              <div className="w-fit m-auto">
                <button
                  type="button"
                  disabled={formData.submitLoader.status === "loading"}
                  className="my_btn px-4 py-2 mx-2 w-36 h-10"
                  onClick={() => handleChangeReqStatus("مقبول")}
                >
                  {formData.submitLoader.type == "مقبول" ? (
                    <div className="loader"></div>
                  ) : (
                    "تأكيد الإضافة"
                  )}
                </button>
                <button
                  type="button"
                  disabled={formData.submitLoader.status === "loading"}
                  className="active_border text_secondary px-4 py-2 mx-2 w-36 h-10 "
                  onClick={() => handleChangeReqStatus("مرفوض")}
                >
                  {formData.submitLoader.type == "مرفوض" ? (
                    <div className="loader"></div>
                  ) : (
                    "رفض الطلب"
                  )}
                </button>
              </div>
              <h6 className="text-center text-red-400 mt-1">
                {formData?.error}
              </h6>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
ReqDetailesModal.propTypes = {
  reqId: PropTypes.string,
  close: PropTypes.func,
  regetRequestis: PropTypes.func,
  req: PropTypes.object,
};
