import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomSubmitButton from "../../reuseables/CustomSubmitButton";
import EscIcon from "../../icons/EscIcon";
import { checkRequired } from "../../../globalFunctions/validation";
import { useNavigate } from "react-router-dom";
import PhoneInput from "../../reuseables/PhoneInput";
import SucsessMessage from "../../reuseables/SucsessMessage";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import Cookies from "js-cookie";

export default function EnterPhoneModal({ close }) {
  const navigate = useNavigate();
  const [formData, setformData] = useState({
    data: { userType: "teacher" },
    submitLoader: { status: "" },
    errors: {},
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    let requiredErrs = checkRequired(
      formData.data,
      ["phone"],
      formData,
      setformData
    );
    if (requiredErrs === "sucsess") {
      setformData({ ...formData, submitLoader: { status: "loading" } });
      setformData({
        ...formData,
        submitLoader: { status: "loading" },
        error: "",
        errors: {},
      });
      myFeach("forgot-password", "POST", formData.data)
        .then(({ data }) => {
          setformData({
            ...formData,
            submitLoader: { status: "done" },
            error: "",
            errors: {},
          });
          setopenFeedBackMessage({
            status: true,
            isSucsess: true,
            message: "تم إرسال الكود",
          });
          Cookies.set("forgotPasswordToken", data?.forgotPasswordToken);
          setTimeout(() => {
            navigate(`/reset-password/otp/${formData?.data?.phone}`);
          }, 2000);
        })
        .catch((err) => {
          setformData({
            ...formData,
            submitLoader: { status: "failed" },
            errors: parceErr(err)?.errors,
            error: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل إرسال الكود",
          });
          setopenFeedBackMessage({
            status: true,
            message: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل إرسال الكود",
          });
        });
    }
  };
  return (
    <div>
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <button onClick={close} className="modal_bg"></button>
      <form
        className="modal_content pb-20 pt-6 p-10 w-10/12 md:w-4/12 "
        onSubmit={handleSubmit}
      >
        <button className="mr-auto block" type="button" onClick={close}>
          <EscIcon />
        </button>
        <h3 className="text-center font-bold text-2xl mb-4">
          هل نسيت كلمة السر؟
        </h3>
        <h4 className="w-11/12 text-center m-auto">
          ادخل رقم الهاتف الخاص بك وسنرسل باقي التعليمات لتعيين كلمة السر
          الجديدة
        </h4>
        <PhoneInput
          width="100%"
          height={""}
          lable={"رقم الهاتف"}
          comment="اختر البلد أولاً"
          err={false}
          required={true}
          name="phone"
          setformData={setformData}
          formData={formData}
          containerClass="mb-10"
        />
        <CustomSubmitButton
          loader={formData.submitLoader}
          err={formData?.error}
          width="100%"
          height="47px"
          title="تأكيد"
        />
      </form>
    </div>
  );
}
EnterPhoneModal.propTypes = {
  close: PropTypes.func,
};
