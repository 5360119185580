import React from "react";

export default function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5137 3.80586C14.5869 2.7311 16.3274 2.73044 17.4013 3.80438L19.8932 6.29624C20.958 7.36102 20.969 9.08478 19.918 10.1632L10.6849 19.6364C9.97933 20.3603 9.01167 20.7685 8.00124 20.7684L5.24909 20.7683C3.96984 20.7682 2.94823 19.7019 3.00203 18.4228L3.12019 15.6138C3.15968 14.6747 3.54996 13.7847 4.2138 13.1199L13.5137 3.80586ZM16.3415 4.86585C15.8533 4.37769 15.0622 4.37799 14.5744 4.86652L12.9113 6.53212L17.1911 10.8119L18.8446 9.11539C19.3224 8.62522 19.3173 7.84169 18.8333 7.3577L16.3415 4.86585ZM5.27446 14.1805L11.8514 7.59358L16.144 11.8862L9.61148 18.5886C9.18816 19.023 8.60756 19.2679 8.0013 19.2679L5.24916 19.2677C4.82274 19.2677 4.4822 18.9123 4.50014 18.4859L4.61829 15.6769C4.64199 15.1134 4.87616 14.5794 5.27446 14.1805ZM20.5148 20.6951C20.9289 20.6951 21.2645 20.3592 21.2645 19.9448C21.2645 19.5305 20.9289 19.1945 20.5148 19.1945H14.3931C13.9791 19.1945 13.6434 19.5305 13.6434 19.9448C13.6434 20.3592 13.9791 20.6951 14.3931 20.6951H20.5148Z"
        fill="#00B378"
      />
    </svg>
  );
}
