import React from "react";

export default function BlockIcon() {
  return (
    <div className="p-1 bg-red-500 circle_rounded">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M12.5 2.5C6.98 2.5 2.5 6.98 2.5 12.5C2.5 18.02 6.98 22.5 12.5 22.5C18.02 22.5 22.5 18.02 22.5 12.5C22.5 6.98 18.02 2.5 12.5 2.5ZM4.5 12.5C4.5 8.08 8.08 4.5 12.5 4.5C14.35 4.5 16.05 5.13 17.4 6.19L6.19 17.4C5.09177 16.0031 4.49639 14.2769 4.5 12.5ZM12.5 20.5C10.65 20.5 8.95 19.87 7.6 18.81L18.81 7.6C19.9082 8.99686 20.5036 10.7231 20.5 12.5C20.5 16.92 16.92 20.5 12.5 20.5Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
