import React, { useEffect, useState } from "react";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import ResultMessage from "../../reuseables/ResultMessage";
import SucsessMessage from "../../reuseables/SucsessMessage";
import PermesionElement from "./PermesionElement";

export default function Permessions() {
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const [currentPermessions, setcurrentPermessions] = useState({
    loader: { status: "loading" },
    data: {},
  });
  const getPermesions = () => {
    myFeach(
      "teachers/application-settings?applicationSettings=permissions",
      "GET",
      {},
      "token"
    )
      .then(({ data }) => {
        setcurrentPermessions({
          data: data.application.permissions,
          loader: { status: "done" },
        });
      })
      .catch((err) => {
        console.log(err);
        setcurrentPermessions({
          data: {},
          loader: {
            status: "failed",
            text: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل تحميل الصلاحيات الحالية",
          },
        });
      });
  };
  useEffect(() => {
    getPermesions();
  }, []);
  return (
    <div>
      {currentPermessions.loader.status == "failed" && (
        <ResultMessage message={currentPermessions.loader.text} />
      )}
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      {currentPermessions.loader.status == "done" && (
        <ul>
          {Object.keys(currentPermessions.data).map((elem, index) => (
            <PermesionElement
              permessionKey={elem}
              getPermesions={getPermesions}
              permesion={currentPermessions.data[elem]}
              key={index}
            />
          ))}
        </ul>
      )}
      {currentPermessions.loader.status == "loading" && (
        <>
          {permessionsList.map((elem, index) => (
            <div className="flex  my-8" key={index}>
              <div className="w-10 h-10 bg-white circle_rounded mx-3"></div>
              <div className="bg-white w-full h-16"></div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

const permessionsList = [
  { text: "ويبسايت", key: "website" },
  { text: "تسجيل الدخول بجهاز واحد", key: "loginWithOneDevice" },
  { text: "إظهار اسم الطالب على الفيديو", key: "studentNameOnVideo" },
  { text: "تسجيل صوتي باسم الطالب على الفيديو", key: "studentNameAsAudio" },
  { text: "تطبيق للموبايل", key: "mobileApp" },
];
