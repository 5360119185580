import React from "react";
import PropTypes from "prop-types";
export default function CustomTable({ children, headers }) {
  return (
    <div className="w-full  overflow-x-scroll mt-10 table_container">
      <table className="w-fit bg-white students_taple my_rounded overflow-hidden ">
        <thead className="bg_primary my_rounded">
          <tr>
            {headers.map((elem, index) => (
              <th className="py-3" key={index}>
                {elem.text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}
CustomTable.propTypes = {
  children: PropTypes.any,
  headers: PropTypes.array,
};
