import React from "react";
import PropTypes from "prop-types";
export default function CustomImage({ src }) {
  return (
    <figure
      className="imge_bg w-full h-full my_rounded "
      style={{ backgroundImage: `url(${src ? src : ""})` }}
    ></figure>
  );
}
CustomImage.propTypes = {
  src: PropTypes.string,
};
