import React, { useEffect, useState } from "react";
import CustomSubmitButton from "../../reuseables/CustomSubmitButton";
import CustomInput from "../../reuseables/CustomInput";
import CustomUploadeInput from "../../reuseables/CustomUploadeInput";
import SucsessMessage from "../../reuseables/SucsessMessage";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import {
  checkRequired,
  compareObjects,
} from "../../../globalFunctions/validation";
import ResultMessage from "../../reuseables/ResultMessage";
import SelectAppColor from "./SelectAppColor";

export default function StudentAppForm() {
  const [oldData, setoldData] = useState({
    loader: { status: "loading" },
    data: {},
  });
  const [appFormData, setappFormData] = useState({
    data: {},
    submitLoader: { status: "" },
    errors: {},
    error: "",
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const getAppOldData = () => {
    myFeach(
      "teachers/application-settings?applicationSettings=settings",
      "GET",
      {},
      "token"
    )
      .then(({ data }) => {
        setoldData({
          loader: { status: "done" },
          data: data.application,
        });
        setappFormData({
          ...appFormData,
          data: data.application,
        });
      })
      .catch((err) => {
        if (parceErr(err)?.code == "40342") {
          setoldData({
            loader: { status: "done" },
            data: {},
          });
          setappFormData({
            ...appFormData,
            data: {},
          });
        } else {
          setoldData({
            loader: {
              status: "failed",
              text: parceErr(err).error
                ? parceErr(err).error
                : "فشل تحميل  بيانات التطبيق الحالية",
            },
          });
        }
      });
  };
  const handleSupmitAppData = (e) => {
    e.preventDefault();
    let requiredErrs = checkRequired(
      appFormData.data,
      ["textDirectionRTL", "color", "logo"],
      appFormData,
      setappFormData
    );
    if (requiredErrs === "sucsess") {
      setappFormData({
        ...appFormData,
        submitLoader: { status: "loading" },
        error: "",
        errors: {},
      });
      let comparedObject = compareObjects(appFormData.data, oldData.data);
      myFeach(
        "teachers/application-settings?data=settings",
        "PUT",
        comparedObject,
        "token"
      )
        .then(() => {
          setappFormData({
            ...appFormData,
            submitLoader: { status: "done" },
            error: "",
            errors: {},
          });
          setoldData({ ...oldData, data: { ...appFormData.data } });
          setopenFeedBackMessage({
            status: true,
            isSucsess: true,
            message: "تم تعديل بيانات التطبيق بنجاح",
          });
        })
        .catch((err) => {
          setappFormData({
            ...appFormData,
            submitLoader: { status: "failed" },
            error: parceErr(err)?.error ? parceErr(err)?.error : "",
            errors: parceErr(err)?.errors ? parceErr(err)?.errors : {},
          });
          setopenFeedBackMessage({
            status: true,
            message: parceErr(err)?.error
              ? parceErr(err)?.error
              : "فشل تعديل بيانات التطبيق حاول مرة أخرى",
          });
        });
    }
  };
  useEffect(() => {
    getAppOldData();
  }, []);

  return (
    <div>
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <h3 className="font-bold">البيانات الخاصة بتطبيق الطالب</h3>
      {oldData.loader.status == "failed" ? (
        <ResultMessage message={oldData.loader.status} />
      ) : (
        <form className="mt-16" onSubmit={handleSupmitAppData}>
          <div className="flex flex-wrap items-center justify-between mb-20 ">
            <div className="md:w-1/2">
              <CustomUploadeInput
                name="logo"
                apiUrl="applicationLogo"
                formData={appFormData}
                setformData={setappFormData}
                disabled={
                  appFormData.submitLoader.status === "loading" ? true : false
                }
                lable="اللوجو"
                required={true}
                getOldDataLoader={oldData.loader.status}
                width="90%"
              />
              <CustomInput
                disabled={
                  appFormData.submitLoader.status === "loading" ? true : false
                }
                width="100%"
                lable={"اللغة"}
                err={false}
                options={[
                  { name: "", value: "" },
                  { name: "الانجليزية", value: "false" },
                  { name: "العربية", value: "true" },
                ]}
                required={true}
                type="select"
                name="textDirectionRTL"
                setformData={setappFormData}
                formData={appFormData}
                containerClass="w-full w-full"
                getOldDataLoader={oldData.loader.status}
              />
            </div>
            <div className="md:w-1/3 w-full mt-5 relative mb-8 ">
              <SelectAppColor
                getOldDataLoader={oldData.loader.status}
                selectedColor={appFormData.data?.color}
                err={appFormData.errors?.color}
                onchange={(color) =>
                  setappFormData({
                    ...appFormData,
                    data: { ...appFormData.data, color: color },
                  })
                }
              />
            </div>
          </div>
          <CustomSubmitButton
            loader={appFormData.submitLoader}
            err={appFormData?.error}
            disabled={
              appFormData.submitLoader.status == "loading" ||
              oldData.loader.status !== "done"
                ? true
                : false
            }
            width="100%"
            height="47px"
            title="حفظ"
          />
        </form>
      )}
    </div>
  );
}
