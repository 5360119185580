import React from "react";

export default function DesafeLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        d="M32.8598 13.9043L30.5619 6.64871C30.4496 6.29398 30.0302 6.11448 29.6275 6.24839L22.2594 8.70159L22.2468 3.93481C22.2412 1.77935 20.7499 0.0284924 18.9163 0.0227939L11.4262 0C11.06 0 10.7626 0.347608 10.764 0.777843L10.7851 8.66455L3.42681 6.25979C3.02277 6.1273 2.6019 6.30822 2.48686 6.66296L0.145408 13.9043C-0.428381 15.6765 0.747256 17.6511 2.76885 18.3121L6.82746 19.6398L4.27557 23.2256C3.03119 24.975 3.22479 27.2844 4.70766 28.3856L10.7668 32.8845C11.0628 33.1054 11.5062 32.9999 11.7545 32.6495L16.5468 25.9153L21.3854 32.6409C21.6366 32.99 22.0813 33.0954 22.3787 32.876L28.4645 28.4141C29.9544 27.3214 30.1466 25.0249 28.8924 23.2826L26.2802 19.6512L30.2224 18.3392C32.2411 17.6667 33.4224 15.6822 32.8598 13.9057V13.9043Z"
        fill="white"
      />
      <path
        d="M32.8598 13.9043L30.5619 6.64871C30.4496 6.29398 30.0302 6.11448 29.6275 6.24839L22.2594 8.70159L22.2468 3.93481C22.2412 1.77935 20.7499 0.0284924 18.9163 0.0227939L11.4262 0C11.06 0 10.7626 0.347608 10.764 0.777843L10.7851 8.66455L3.42681 6.25979C3.02277 6.1273 2.6019 6.30822 2.48686 6.66296L0.145408 13.9043C-0.428381 15.6765 0.747256 17.6511 2.76885 18.3121L6.82746 19.6398L4.27557 23.2256C3.03119 24.975 3.22479 27.2844 4.70766 28.3856L10.7668 32.8845C11.0628 33.1054 11.5062 32.9999 11.7545 32.6495L16.5468 25.9153L21.3854 32.6409C21.6366 32.99 22.0813 33.0954 22.3787 32.876L28.4645 28.4141C29.9544 27.3214 30.1466 25.0249 28.8924 23.2826L26.2802 19.6512L30.2224 18.3392C32.2411 17.6667 33.4224 15.6822 32.8598 13.9057V13.9043Z"
        fill="url(#paint0_linear_3988_5040)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3988_5040"
          x1="29.7"
          y1="0.659999"
          x2="3.696"
          y2="32.208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23DFF8" />
          <stop offset="1" stopColor="#1D68DF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
