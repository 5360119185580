import React, { useState } from "react";
import CustomInput from "../../components/reuseables/CustomInput";
import CustomSubmitButton from "../../components/reuseables/CustomSubmitButton";
import { useNavigate } from "react-router-dom";
import { checkRequired } from "../../globalFunctions/validation";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import Cookies from "js-cookie";
import SucsessMessage from "../../components/reuseables/SucsessMessage";
export default function ResetPassword() {
  const navigate = useNavigate();
  const [formData, setformData] = useState({
    data: { password: "" },
    submitLoader: { status: "" },
    errors: {},
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    let requiredErrs = checkRequired(
      formData.data,
      ["newPassword", "confirmResetPassword"],
      formData,
      setformData
    );
    if (requiredErrs === "sucsess") {
      let finalObjectFormat = {
        newPassword: formData.data.newPassword,
        resetPasswordToken: Cookies.get("resetPasswordToken"),
      };
      setformData({
        ...formData,
        submitLoader: { status: "loading" },
        errors: {},
        error: "",
      });
      myFeach("reset-password", "PUT", finalObjectFormat, "forgotPasswordToken")
        .then(() => {
          setformData({
            ...formData,
            submitLoader: { status: "done" },
            errors: {},
            error: "",
          });
          setopenFeedBackMessage({
            status: true,
            isSucsess: true,
            message:
              "تم  تغير الرقم السري بنجاح يمكنك تسجيل الدخول بالرمز الجديد",
          });
          Cookies.remove("forgotPasswordToken");
          Cookies.remove("resetPasswordToken");
          setTimeout(() => {
            navigate("/log-in");
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          setformData({
            ...formData,
            submitLoader: { status: "failed" },
            errors: parceErr(err)?.errors,
            error: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل  تغير الرقم السري",
          });
          setopenFeedBackMessage({
            status: true,
            message: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل  تغير الرقم السري",
          });
        });
    }
  };
  return (
    <main className="full_page bg_primary ">
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <form
        className="m-auto w-fit bg_greay large_rounded p-10  my-14"
        onSubmit={handleSubmit}
      >
        <h3 className="text-center font-bold text-2xl mb-12">
          تعين كلمة سر جديدة
        </h3>
        <CustomInput
          width="450px"
          height={""}
          lable={"كلمة السر"}
          comment={"يجب أن يحتوي على 8 أرقام"}
          placeHolder={""}
          err={false}
          required={true}
          type="password"
          name="newPassword"
          setformData={setformData}
          formData={formData}
        />
        <CustomInput
          width="450px"
          height={""}
          lable={"تأكيد كلمة السر"}
          comment={"يجب أن يكونا متطابقان"}
          placeHolder={""}
          err={false}
          required={true}
          type="password"
          name="confirmResetPassword"
          setformData={setformData}
          formData={formData}
          disabled={formData.data?.newPassword ? false : true}
          containerClass="mb-10 mt-10"
        />
        <CustomSubmitButton
          loader={formData.submitLoader}
          err={formData?.error}
          width="100%"
          height="47px"
          title="تغير الرقم السري"
        />
      </form>
    </main>
  );
}
