import React, { useEffect, useState } from "react";
import { myFeach, parceErr } from "../globalFunctions/Api";
import PromoCodeIcon from "../components/icons/PromoCodeIcon";
import SucsessMessage from "../components/reuseables/SucsessMessage";
import ResultMessage from "../components/reuseables/ResultMessage";

export default function PromoCode() {
  const [promoCode, setpromoCode] = useState({
    data: {},
    loader: { status: "loading" },
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const getPromo = () => {
    myFeach("promocodes", "GET", {}, "token")
      .then(({ data }) => {
        if (data.promocode == null) {
          setpromoCode({
            loader: { status: "failed", text: "لا يوجد بروموكود بعد" },
          });
        } else {
          setpromoCode({ data: data.promocode, loader: { status: "done" } });
        }
      })
      .catch((err) => {
        let textErr = parceErr(err).error
          ? parceErr(err).error
          : "فشل تحميل البروموكود";
        setpromoCode({ loader: { status: "failed", text: textErr } });
      });
  };
  const copyToClipboard = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: `تم نسح : ${textToCopy}`,
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  useEffect(() => {
    getPromo();
  }, []);
  return (
    <main>
      {openFeedBackMessage.status === true && (
        <SucsessMessage
          isSucsess={openFeedBackMessage.isSucsess}
          message={openFeedBackMessage.message}
          close={() => setopenFeedBackMessage({ status: false })}
        />
      )}
      {promoCode.loader.status === "done" && (
        <div className="my_rounded bg-white  py-10 px-8 m-auto w-fit">
          <div
            className="text_hover_active"
            onClick={() => copyToClipboard(promoCode.data.code)}
          >
            نسخ الكود
          </div>
          <div className="m-auto flex flex-col items-center">
            <div className="p-3 bg_secondary my_rounded">
              <PromoCodeIcon />
            </div>
            <h4>البروموكود الخاص بك</h4>
            <h2 className="text_secondary">{promoCode.data.code}</h2>
            <h5>تم استخدامه {promoCode.data?.noOfUses} مرات</h5>
            <div className="my_rounded bg-gray-200 w-3/4 h-5 my-6">
              <div
                className="my_rounded bg_secondary h-full"
                style={{
                  width: `${(promoCode.data.noOfSubscriptionMonthes / 60) * 100}%`,
                }}
              ></div>
            </div>
            <h4>
              لديك{" "}
              <span className="font-bold">
                {promoCode.data.noOfSubscriptionMonthes * 10} نقطة
              </span>{" "}
            </h4>
            <h5 className="mt-10 max-w-96 text-center ">
              قم بمشاركة هذا البرومو كود مع زملائك من المعلمين، للحصول على
              المزيد من النقاط عند اشتراكهم باستخدام الكود الخاص بك عن طريق نسخ
              الكود التالي وإرساله لزملائك
            </h5>
          </div>
        </div>
      )}
      {promoCode.loader.status === "loading" && (
        <div className="bg-white md:w-2/6 w-5/6 h-3/4 m-auto my_rounded py-10 px-8 flex items-center flex-col">
          <div className="h-16 w-1/4 my_rounded bg_greay my-3"></div>
          <div className="w-full h-8 bg_greay my-2"></div>
          <div className="w-full h-16 bg_greay my-2"></div>

          <div className="w-full h-8 bg_greay my-2"></div>
          <div className="w-full h-32 bg_greay my-2"></div>
        </div>
      )}
      {promoCode.loader.status === "failed" && (
        <ResultMessage message={promoCode.loader.text} />
      )}
    </main>
  );
}
