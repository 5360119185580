import React from "react";
import PropTypes from "prop-types";
export default function DeleteVideoConfirm({ confirm, close, loader }) {
  return (
    <>
      <button onClick={close} className="modal_bg"></button>
      <div className="modal_content px-8 py-4 ">
        <h3 className="mb-6">هل إنت متأكد من حذف الفيديو؟</h3>
        <div className="border_top_greay pt-10 pb-4 w-full flex justify-center">
          <div style={{ width: "112px" }}>
            <button
              type="button"
              onClick={confirm}
              disabled={loader.status === "loading" ? true : false}
              style={{ width: "112px", height: "32px" }}
              className="bg_gradiant my_rounded"
            >
              {loader.status === "loading" ? (
                <div className="loader"></div>
              ) : (
                "حذف"
              )}
            </button>
            <h6 className="text_danger w-full text-center mt-1">
              {loader.text}
            </h6>
          </div>
          <button
            type="submit"
            className="mx-2 w-28 h-8 gradient_border"
            onClick={close}
          >
            إلغاء
          </button>
        </div>
      </div>
    </>
  );
}
DeleteVideoConfirm.propTypes = {
  close: PropTypes.func,
  confirm: PropTypes.func,
  loader: PropTypes.object,
};
