import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import AddDocIcon from "../icons/AddDocIcon";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import RightIcon from "../icons/RightIcon";
import InputScaleable from "./InputScaleable";
import LoaderModal from "./LoaderModal";
import { cutString } from "../../globalFunctions/TextModify";
export default function CustomUplodeDoc({
  lable,
  required,
  disabled,
  formData,
  setformData,
  name = "",
  apiUrl,
  getOldDataLoader = "done",
}) {
  const inputRef = useRef(null);
  const [loader, setloader] = useState("noAction");
  const [uplodeErr, setuplodeErr] = useState();

  const uplodeDoc = async (e) => {
    let file = await e.target.files[0];
    let Imageformdata = new FormData();
    Imageformdata.append("file", file);
    if (validatePdf(file)) {
      setloader("loading");
      setuplodeErr("");
      myFeach(
        `upload-file/?type=${apiUrl}`,
        "POST",
        Imageformdata,
        "token",
        true
      )
        .then(({ data }) => {
          inputRef.current.value = "";
          setloader("done");
          setuplodeErr("");
          setformData({
            ...formData,
            data: { ...formData.data, [name]: data.file },
            errors: { ...formData.errors, [name]: "" },
          });
        })
        .catch((err) => {
          console.log(err);
          inputRef.current.value = "";
          setloader("failed");
          setuplodeErr(
            parceErr(err)?.error
              ? parceErr(err).error
              : "فشل رفع الصورة حاول مرة أخرى"
          );
        });
    } else {
      setuplodeErr("مسموح برفع الملف بصيغة pdf فقط");
    }
  };
  useEffect(() => {
    if (!formData.data[name]) {
      setloader("noAction");
    }
  }, [formData.data[name]]);
  return (
    <>
      {loader === "loading" && <LoaderModal lable="جاري رفع الملف" />}
      {getOldDataLoader === "done" ? (
        <div className={`mb-10 `}>
          <label className="mb-5 block font-bold text-sm ">
            {lable} {required && <span className="text_danger">*</span>}{" "}
          </label>
          <div className="flex items-center  ">
            <button
              disabled={disabled}
              type="button"
              onClick={() => inputRef.current.click()}
              className={`w-44 h-28 my_rounded flex items-center justify-center ml-4 bg-white   ${formData?.errors[name] || uplodeErr ? "danger_border" : ""}`}
            >
              <div>
                {loader == "loading" && <div className="loader"></div>}
                {formData.data[name] && (
                  <div className="flex items-center justify-center flex-col">
                    <div className="bg-green-400 circle_rounded p-1 w-fit">
                      <RightIcon />
                    </div>
                    <h6>{cutString(formData.data[name], 20)}</h6>
                  </div>
                )}
                {loader != "loading" && !formData.data[name] && <AddDocIcon />}
              </div>
            </button>
            <h4>قم برفع المستند بصيغة (pdf)</h4>
            <input
              ref={inputRef}
              disabled={disabled}
              type="file"
              className="display_none"
              onChange={uplodeDoc}
            />
          </div>
          <h4 className="text-xs text_danger mt-2">{formData?.errors[name]}</h4>
          <h4 className="text-xs text_danger mt-2">{uplodeErr}</h4>
        </div>
      ) : (
        <InputScaleable width="70%" height="40px" />
      )}
    </>
  );
}
CustomUplodeDoc.propTypes = {
  lable: PropTypes.string,
  apiUrl: PropTypes.string,
  btn_class: PropTypes.string,
  name: PropTypes.string,
  getOldDataLoader: PropTypes.string,
  formData: PropTypes.object,
  setformData: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
let validatePdf = (file) => {
  let flag = "";
  const allowedTypes = ["application/pdf"];
  console.log(file.type);
  if (allowedTypes.includes(file.type)) {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};
