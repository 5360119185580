import Cookies from "js-cookie";
const API_URL = process.env.REACT_APP_API_URL;
// ===============================start post method
var myHeaders = new Headers();
const restoreHeader = () => {
  myHeaders.forEach((value, header) => {
    myHeaders.delete(header);
  });
  myHeaders.delete("deviceType");
  myHeaders.delete("deviceFingerprint");
};
const appendToken = (authToken) => {
  authToken &&
    myHeaders.append("Authorization", `Bearer ${Cookies.get(authToken)}`);
};
const requestOptions = (
  bodyObject,
  methodType,
  authToken,
  isUplode = false,
  contentType
) => {
  let myReqOptions;
  restoreHeader();
  myHeaders.append("deviceType", "website");
  myHeaders.append("deviceFingerprint", "fingerprint123");
  if (methodType === "POST" || methodType === "PUT") {
    !isUplode && myHeaders.append("Content-Type", "application/json");
    contentType && myHeaders.append("Content-Type", contentType);
    appendToken(authToken);
    let body = isUplode ? bodyObject : JSON.stringify(bodyObject);
    myReqOptions = {
      method: methodType,
      headers: myHeaders,
      body: body,
      redirect: "follow",
    };
  } else {
    appendToken(authToken);
    authToken === "json" &&
      myHeaders.append("Content-Type", "application/json");
    myReqOptions = {
      method: methodType,
      headers: myHeaders,
      redirect: "follow",
    };
  }
  return myReqOptions;
};
// handle GENRAL fech method
const handleFeachResult = (result) => {
  if (result.error || result.errors || result.err || result.status == 404) {
    if (
      anAutherizedUserCodes.includes(result.code.toString()) &&
      window.location.pathname != "/log-in" &&
      !window.location.pathname.includes("otp")
    ) {
      // alert("يجب تسجيل الدخول مرة أخرى");
      Cookies.remove("token");
      // window.location.href = "/log-in/auth-problem";
    }
    throw new Error(result ? JSON?.stringify(result) : "failed");
  } else {
    return result;
  }
};
export const myFeach = (
  url,
  methodType,
  bodyObject,
  authToken = false,
  isUplode = false,
  contentType
) =>
  fetch(
    `${API_URL}/${url}`,
    requestOptions(bodyObject, methodType, authToken, isUplode, contentType)
  )
    .then((response) => {
      return response && response?.json();
    })
    .then((result) => {
      restoreHeader();
      return handleFeachResult(result);
    });
// parce err message
export const parceErr = (err) => {
  const myErr = JSON?.parse(err?.message);
  if (!myErr?.errors) {
    myErr.errors = {};
  }
  if (!myErr?.error) {
    myErr.error = "";
  }
  return myErr;
};
// this codes retern when user hava any problem in token
const anAutherizedUserCodes = [
  "40100",
  "40101",
  "40102",
  "40103",
  "40104",
  "40110",
  "40111",
  "40112",
  // "50000",
];
