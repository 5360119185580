import React from "react";

export default function InstgramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M10.12 2H21.88C26.36 2 30 5.64 30 10.12V21.88C30 24.0336 29.1445 26.0989 27.6217 27.6217C26.0989 29.1445 24.0336 30 21.88 30H10.12C5.64 30 2 26.36 2 21.88V10.12C2 7.96644 2.8555 5.90109 4.37829 4.37829C5.90109 2.8555 7.96644 2 10.12 2ZM9.84 4.8C8.50331 4.8 7.22137 5.331 6.27618 6.27618C5.331 7.22137 4.8 8.50331 4.8 9.84V22.16C4.8 24.946 7.054 27.2 9.84 27.2H22.16C23.4967 27.2 24.7786 26.669 25.7238 25.7238C26.669 24.7786 27.2 23.4967 27.2 22.16V9.84C27.2 7.054 24.946 4.8 22.16 4.8H9.84ZM23.35 6.9C23.8141 6.9 24.2592 7.08437 24.5874 7.41256C24.9156 7.74075 25.1 8.18587 25.1 8.65C25.1 9.11413 24.9156 9.55925 24.5874 9.88744C24.2592 10.2156 23.8141 10.4 23.35 10.4C22.8859 10.4 22.4408 10.2156 22.1126 9.88744C21.7844 9.55925 21.6 9.11413 21.6 8.65C21.6 8.18587 21.7844 7.74075 22.1126 7.41256C22.4408 7.08437 22.8859 6.9 23.35 6.9ZM16 9C17.8565 9 19.637 9.7375 20.9497 11.0503C22.2625 12.363 23 14.1435 23 16C23 17.8565 22.2625 19.637 20.9497 20.9497C19.637 22.2625 17.8565 23 16 23C14.1435 23 12.363 22.2625 11.0503 20.9497C9.7375 19.637 9 17.8565 9 16C9 14.1435 9.7375 12.363 11.0503 11.0503C12.363 9.7375 14.1435 9 16 9ZM16 11.8C14.8861 11.8 13.8178 12.2425 13.0302 13.0302C12.2425 13.8178 11.8 14.8861 11.8 16C11.8 17.1139 12.2425 18.1822 13.0302 18.9698C13.8178 19.7575 14.8861 20.2 16 20.2C17.1139 20.2 18.1822 19.7575 18.9698 18.9698C19.7575 18.1822 20.2 17.1139 20.2 16C20.2 14.8861 19.7575 13.8178 18.9698 13.0302C18.1822 12.2425 17.1139 11.8 16 11.8Z"
        fill="#4271FF"
      />
    </svg>
  );
}
