import React, { useEffect, useState } from "react";
import CourseCard from "../../components/pagesComponents/courses/CourseCard";
import CustomSearch from "../../components/reuseables/CustomSearch";
import { Link } from "react-router-dom";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import ResultMessage from "../../components/reuseables/ResultMessage";

export default function Courses() {
  const [coursesList, setcoursesList] = useState({
    list: ["", "", "", "", "", "", "", "", "", ""],
    loader: { status: "loading", text: "" },
  });
  const [searchKey, setsearchKey] = useState("");
  const handleGetCourseList = () => {
    if (coursesList.loader.status !== "loading") {
      setcoursesList({
        list: ["", "", "", "", "", "", "", "", "", ""],
        loader: { status: "loading", text: "" },
      });
    }
    myFeach(
      `courses${searchKey ? `?searchKey=${searchKey}` : ""}`,
      "GET",
      {},
      "token"
    )
      .then(({ data }) => {
        setcoursesList({ list: data.courses, loader: { status: "done" } });
        if (data.courses.length == 0) {
          setcoursesList({
            list: data.courses,
            loader: {
              status: "failed",
              text: "لا يوجد نتائج حاول مرة أخرى",
            },
          });
        }
      })
      .catch((err) => {
        setcoursesList({
          loader: {
            status: "failed",
            text: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل تحميل الكورسات",
          },
        });
      });
  };
  useEffect(() => {
    handleGetCourseList();
  }, [searchKey]);
  // useEffect(() => {
  //   const test = (e) => {
  //     e.preventDefault();
  //     console.log("hii");
  //     confirm("test");
  //   };
  //   window.addEventListener("beforeunload", test);
  // }, []);
  return (
    <main className="">
      <Link
        to="/add-course"
        className="gradient_border px-4 py-2 mb-8 block w-fit "
      >
        إضافة كورس جديد
      </Link>
      <CustomSearch
        loader={coursesList.loader}
        onchangeFun={(key) => setsearchKey(key)}
        width={"240px"}
        placeHolder={"ابحث عن اسم الكورس"}
      />
      <div className="flex flex-wrap">
        {coursesList.loader.status === "failed" ? (
          <ResultMessage message={coursesList.loader?.text} />
        ) : (
          <>
            {coursesList?.list.map((course, index) => (
              <CourseCard
                loader={coursesList.loader}
                key={index}
                cardData={course}
              />
            ))}
          </>
        )}
      </div>
    </main>
  );
}
