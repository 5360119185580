import React, { useState } from "react";
import PropTypes from "prop-types";
import PersonalDataForm from "../components/pagesComponents/settings/PersonalDataForm";
import StudentAppForm from "../components/pagesComponents/settings/StudentAppForm";
import Permessions from "../components/pagesComponents/settings/Permessions";

export default function Settings({ regetTopBarData }) {
  const [pageFilter, setpageFilter] = useState("personal");
  return (
    <main className="">
      <div className="flex w-fit m-auto my_rounded overflow-hidden md:bg-white shadow-sm md:p-0 p-3 bg_greay flex-wrap ">
        <button
          onClick={() => setpageFilter("personal")}
          className={`${pageFilter === "personal" ? "bg_secondary" : ""} px-6 py-2 bg-white my-2 md:my-0 min-w-52`}
        >
          البيانات الشخصية
        </button>
        <button
          onClick={() => setpageFilter("app")}
          className={`${pageFilter === "app" ? "bg_secondary" : ""} px-6 py-2 left_right_border  bg-white my-2 md:my-0 min-w-52`}
        >
          بيانات تطبيق الطالب
        </button>
        <button
          onClick={() => setpageFilter("permesions")}
          className={`${pageFilter === "permesions" ? "bg_secondary" : ""} px-6 py-2  bg-white my-2 md:my-0 min-w-52`}
        >
          الصلاحيات
        </button>
      </div>
      {pageFilter == "personal" && (
        <PersonalDataForm regetTopBarData={regetTopBarData} />
      )}
      {pageFilter == "app" && <StudentAppForm />}
      {pageFilter == "permesions" && <Permessions />}
    </main>
  );
}
Settings.propTypes = {
  regetTopBarData: PropTypes.func,
};
