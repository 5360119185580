import React from "react";
import PlanOverView from "../components/pagesComponents/subscreptions/PlanOverView";
import { Link } from "react-router-dom";
import CurrentRequests from "../components/pagesComponents/students/CurrentRequests";
export default function OverView() {
  return (
    <main>
      <PlanOverView />
      <div className="flex justify-between mt-10">
        <h4>طلبات الطلبة الجديدة</h4>
        <Link className="text_secondary " to="/requests">
          عرض جميع الطلبات
        </Link>
      </div>
      <CurrentRequests />
    </main>
  );
}
