import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RightArrowIcon from "../../components/icons/RightArrowIcon";
import CustomInput from "../../components/reuseables/CustomInput";
import InstgramIcon from "../../components/icons/InstgramIcon";
import FaceBookIcon from "../../components/icons/FaceBookIcon";
import TwitterIcon from "../../components/icons/TwitterIcon";
import CustomSubmitButton from "../../components/reuseables/CustomSubmitButton";
import CustomUploadeInput from "../../components/reuseables/CustomUploadeInput";
import SelectFromCheckBox from "../../components/reuseables/SelectFromCheckBox";
import PhoneInput from "../../components/reuseables/PhoneInput";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import {
  checkRequired,
  removeEmptyValues,
} from "../../globalFunctions/validation";
import SucsessMessage from "../../components/reuseables/SucsessMessage";
import SelectAppColor from "../../components/pagesComponents/settings/SelectAppColor";
export default function CompleteInformation() {
  const navigate = useNavigate();
  const [personalFormData, setpersonalFormData] = useState({
    data: { categories: [] },
    submitLoader: { status: "" },
    errors: {},
    error: "",
  });
  const [appFormData, setappFormData] = useState({
    data: {},
    submitLoader: { status: "" },
    errors: {},
    error: "",
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleNavigate = (otherFormStatus) => {
    if (otherFormStatus) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  };
  const handleSupmitPersonalData = (e) => {
    e.preventDefault();
    let requiredErrs = checkRequired(
      personalFormData.data,
      ["profileImage", "categories", "whatsapp", "brief"],
      personalFormData,
      setpersonalFormData
    );
    if (requiredErrs === "sucsess") {
      setpersonalFormData({
        ...personalFormData,
        submitLoader: { status: "loading", error: "", errors: {} },
      });
      myFeach(
        "teachers/personal-data",
        "PUT",
        removeEmptyValues(personalFormData.data),
        "token"
      )
        .then(() => {
          setpersonalFormData({
            ...personalFormData,
            submitLoader: { status: "done" },
            error: "",
            errors: {},
          });
          setopenFeedBackMessage({
            status: true,
            isSucsess: true,
            message: "تم إضافة بيانتك بنجاح",
          });
          handleNavigate(appFormData?.submitLoader?.status);
        })
        .catch((err) => {
          setpersonalFormData({
            ...personalFormData,
            submitLoader: { status: "failed" },
            error: parceErr(err)?.error ? parceErr(err)?.error : "",
            errors: parceErr(err)?.errors ? parceErr(err)?.errors : {},
          });
          setopenFeedBackMessage({
            status: true,
            message: parceErr(err)?.error
              ? parceErr(err)?.error
              : "فشل إضافة البيانات حاول مرة أخرى",
          });
        });
    }
  };
  const handlesubmitAppData = (e) => {
    e.preventDefault();
    let requiredErrs = checkRequired(
      appFormData.data,
      ["logo", "color", "textDirectionRTL"],
      appFormData,
      setappFormData
    );
    if (requiredErrs === "sucsess") {
      setappFormData({
        ...appFormData,
        error: "",
        errors: {},
        submitLoader: { status: "loading" },
      });
      myFeach(
        "teachers/application-settings?data=settings",
        "PUT",
        removeEmptyValues(appFormData.data),
        "token"
      )
        .then(() => {
          setappFormData({
            ...appFormData,
            submitLoader: { status: "done" },
            errors: {},
            error: "",
          });
          setopenFeedBackMessage({
            status: true,
            isSucsess: true,
            message: "تم إضافة بيانات تطبيق الطالب بنجاح",
          });
          handleNavigate(personalFormData?.submitLoader?.status);
        })
        .catch((err) => {
          setappFormData({
            ...appFormData,
            submitLoader: { status: "failed" },
            error: parceErr(err)?.error ? parceErr(err)?.error : "",
            errors: parceErr(err)?.errors ? parceErr(err)?.errors : {},
          });
          setopenFeedBackMessage({
            status: true,
            message: parceErr(err)?.error
              ? parceErr(err)?.error
              : "فشل إضافة بيانات تطبيق الطالب حاول مرة أخرى",
          });
        });
    }
  };
  return (
    <main className="full_page pt-20 bg_greay ">
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <div className="w-10/12 md:w-3/5 m-auto">
        <Link className=" flex items-center text_primary w-fit" to="/">
          <RightArrowIcon />
          <span>لاحقا</span>
        </Link>
        <h1 className=" text-center font-bold text-3xl w-11/12 md:w-3/5 m-auto ">
          من فضلك قم باستكمال بياناتك حتى يتمكن الطلبة من الوصول للمحتوى الخاص
          بك
        </h1>
        <form className="mt-10" onSubmit={handleSupmitPersonalData}>
          <CustomUploadeInput
            name="profileImage"
            apiUrl="profileImage"
            formData={personalFormData}
            setformData={setpersonalFormData}
            disabled={
              personalFormData.submitLoader.status === "done" ||
              personalFormData.submitLoader.status === "loading"
                ? true
                : false
            }
            lable="الصورة الشخصية"
            required={true}
          />
          <SelectFromCheckBox
            lable="التخصصات التي تقوم بتدريسها"
            required={true}
            disabled={
              personalFormData.submitLoader.status === "done" ||
              personalFormData.submitLoader.status === "loading"
                ? true
                : false
            }
            formData={personalFormData}
            setformData={setpersonalFormData}
            objectKey={"categories"}
            apiUrl="categories"
          />
          <PhoneInput
            lable={"رقم ال whatsapp"}
            disabled={
              personalFormData.submitLoader.status === "done" ||
              personalFormData.submitLoader.status === "loading"
                ? true
                : false
            }
            err={false}
            required={true}
            width="100%"
            name="whatsapp"
            setformData={setpersonalFormData}
            formData={personalFormData}
            comment="تأكد من أن الرقم له واتساب (قم باختيار الدولة أولا)"
            containerClass="w-full"
          />
          <CustomInput
            disabled={
              personalFormData.submitLoader.status === "done" ||
              personalFormData.submitLoader.status === "loading"
                ? true
                : false
            }
            width="100%"
            height="100px"
            lable={"نبذة عن المدرس"}
            required={true}
            type="textArea"
            name="brief"
            setformData={setpersonalFormData}
            formData={personalFormData}
          />
          <div className="mt-10 mb-8  md:w-1/3 w-full">
            <h5>مواقع التواصل الاجتماعي</h5>
            <div className="flex items-center w-full justify-between mb-5 ">
              <div className="mt-2">
                <InstgramIcon />
              </div>
              <CustomInput
                disabled={
                  personalFormData.submitLoader.status === "done" ||
                  personalFormData.submitLoader.status === "loading"
                    ? true
                    : false
                }
                width="90%"
                placeHolder="Instgram URL"
                type=""
                name="instagram"
                setformData={setpersonalFormData}
                formData={personalFormData}
                lang="en"
                containerClass="w-full mr-4"
              />
            </div>
            <div className="flex items-center w-full justify-between mb-5 ">
              <div className="mt-2">
                <FaceBookIcon />
              </div>
              <CustomInput
                disabled={
                  personalFormData.submitLoader.status === "done" ||
                  personalFormData.submitLoader.status === "loading"
                    ? true
                    : false
                }
                width="90%"
                err={false}
                placeHolder="Facebook URL"
                type=""
                name="facebook"
                setformData={setpersonalFormData}
                formData={personalFormData}
                lang="en"
                containerClass="w-full mr-4"
              />
            </div>
            <div className="flex items-center w-full justify-between mb-5 ">
              <div className="mt-2">
                <TwitterIcon />
              </div>
              <CustomInput
                disabled={
                  personalFormData.submitLoader.status === "done" ||
                  personalFormData.submitLoader.status === "loading"
                    ? true
                    : false
                }
                width="90%"
                err={false}
                placeHolder="Twitter URL"
                type=""
                name="X"
                setformData={setpersonalFormData}
                formData={personalFormData}
                lang="en"
                containerClass="w-full mr-4"
              />
            </div>
          </div>
          {personalFormData.submitLoader.status === "done" ? (
            <div className="w-full h-12 my_rounded bg_gradiant text-center py-3 opacity-50 ">
              تم حفظ البيانات
            </div>
          ) : (
            <CustomSubmitButton
              loader={personalFormData.submitLoader}
              err={personalFormData?.error}
              width="100%"
              height="47px"
              title="حفظ"
            />
          )}
          {personalFormData.submitLoader.status === "done" && (
            <h4 className="w-full text-center text_primary text-xs mt-2">
              يمكنك التعديل على البيانات في أي وقت من خلال صفحة الإعدادات
            </h4>
          )}
        </form>
        <form className="mt-16" onSubmit={handlesubmitAppData}>
          <h4 className="mb-10 font-bold">البيانات الخاصة بتطبيق الطالب</h4>
          <CustomUploadeInput
            name="logo"
            apiUrl="applicationLogo"
            formData={appFormData}
            setformData={setappFormData}
            disabled={
              appFormData.submitLoader.status === "done" ||
              appFormData.submitLoader.status === "loading"
                ? true
                : false
            }
            lable="اللوجو"
            required={true}
          />

          <CustomInput
            disabled={
              appFormData.submitLoader.status === "loading" ? true : false
            }
            width="100%"
            height={""}
            lable={"اللغة"}
            err={false}
            options={[
              { name: "", value: "" },
              { name: "الانجليزية", value: "false" },
              { name: "العربية", value: "true" },
            ]}
            required={true}
            type="select"
            name="textDirectionRTL"
            setformData={setappFormData}
            formData={appFormData}
            containerClass="md:w-1/3 w-full"
          />
          <div className="md:w-1/3 w-full mt-5 relative mb-8 ">
            {/* <CustomInput
              disabled={
                appFormData.submitLoader.status === "loading" ? true : false
              }
              width="100%"
              height={""}
              lable={"لون التطبيق"}
              err={false}
              options={colors}
              required={true}
              type="select"
              name="color"
              setformData={setappFormData}
              formData={appFormData}
              containerClass="w-full"
            /> */}
            <SelectAppColor
              selectedColor={appFormData.data?.color}
              err={appFormData.errors?.color}
              onchange={(color) =>
                setappFormData({
                  ...appFormData,
                  data: { ...appFormData.data, color: color },
                })
              }
            />
          </div>
          {appFormData.submitLoader.status === "done" ? (
            <div className="w-full h-12 my_rounded bg_gradiant text-center py-3 opacity-40 ">
              تم حفظ بيانات التطبيق
            </div>
          ) : (
            <CustomSubmitButton
              loader={appFormData.submitLoader}
              err={appFormData?.error}
              width="100%"
              height="47px"
              title="حفظ"
            />
          )}
          {appFormData.submitLoader.status === "done" && (
            <h4 className="w-full text-center text_primary text-xs mt-2">
              يمكنك التعديل على البيانات في أي وقت من خلال صفحة الإعدادات
            </h4>
          )}
        </form>
      </div>
    </main>
  );
}
// const colors = [
//   { name: "", value: "" },
//   { name: "لبني", value: "#0099FF" },
//   { name: "فيروزي فاتح", value: "#20DFD9" },
//   { name: "أخضر عشبي", value: "#66B342" },
//   { name: "بيج", value: "#E4BD81" },
//   { name: "عنّابي", value: "#8E016F" },
//   { name: "رمادي", value: "#787272" },
// ];
