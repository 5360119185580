import React from "react";
import PropTypes from "prop-types";
export default function PasswordIcon({ hilight }) {
  return (
    <div className={`${hilight ? "fill_primary" : ""}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.9987 3.75C5.83203 3.75 2.2737 6.34167 0.832031 10C2.2737 13.6583 5.83203 16.25 9.9987 16.25C14.1654 16.25 17.7237 13.6583 19.1654 10C17.7237 6.34167 14.1654 3.75 9.9987 3.75ZM9.9987 14.1667C7.6987 14.1667 5.83203 12.3 5.83203 10C5.83203 7.7 7.6987 5.83333 9.9987 5.83333C12.2987 5.83333 14.1654 7.7 14.1654 10C14.1654 12.3 12.2987 14.1667 9.9987 14.1667ZM9.9987 7.5C8.61537 7.5 7.4987 8.61667 7.4987 10C7.4987 11.3833 8.61537 12.5 9.9987 12.5C11.382 12.5 12.4987 11.3833 12.4987 10C12.4987 8.61667 11.382 7.5 9.9987 7.5Z"
          fill="#555555"
        />
      </svg>
    </div>
  );
}
PasswordIcon.propTypes = {
  hilight: PropTypes.bool,
};
