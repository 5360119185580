import React from "react";
import PropTypes from "prop-types";
export default function SelectFilter({
  onchangeFun,
  list,
  loader,
  activeChoice,
  message,
  width,
  disabled,
  marginLeft="4"
}) {
  return (
    <div className={`my-2 relative ml-${marginLeft} }`}>
      {loader.status == "done" ? (
        <>
          {!activeChoice && (
            <h4 className="text_greay opacity-50 font-normal text-sm absolute top-2 right-2">
              {message}
            </h4>
          )}
          <select
            style={{ width: width }}
            className=""
            onChange={(e) => onchangeFun(e.target.value)}
            disabled={disabled}
          >
            <option></option>
            {list.map((elem, index) => (
              <option key={index} value={elem.id ? elem.id : elem?.value}>
                {elem.name}
              </option>
            ))}
          </select>
        </>
      ) : (
        <>
          <div
            style={{ width: width }}
            className=" h-10 text-xs text-red-400 bg-white items-center justify-center flex my_rounded "
          >
            {loader.status === "failed" ? (
              <>{loader.text}</>
            ) : (
              <div className="loader"></div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
SelectFilter.propTypes = {
  message: PropTypes.string,
  onchangeFun: PropTypes.func,
  list: PropTypes.array,
  loader: PropTypes.object,
  activeChoice: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  marginLeft: PropTypes.string,
};
