import React, { useState } from "react";
import PropTypes from "prop-types";
import SucsessMessage from "../../reuseables/SucsessMessage";
import CustomSubmitButton from "../../reuseables/CustomSubmitButton";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
export default function AddStudentNoteModal({
  close,
  currentNote = "",
  studentId,
  regetStudent,
}) {
  const [formData, setformData] = useState({
    data: { note: currentNote },
    loader: { status: "" },
    errors: {},
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleSubmitNote = (e) => {
    e.preventDefault();
    setformData({
      ...formData,
      error: "",
      errors: {},
      loader: { status: "loading" },
    });
    myFeach(`students/${studentId}`, "PUT", formData.data, "token")
      .then((data) => {
        console.log(data);
        setformData({
          ...formData,
          error: "",
          errors: {},
          loader: { status: "done" },
        });
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: "تم إضافة الملحوظة بنجاح",
        });
        regetStudent();
      })
      .catch((err) => {
        console.log(err);
        let errText = parceErr(err)?.error
          ? parceErr(err)?.error
          : "فشل إضافة التعليق";
        let inputErr = parceErr(err)?.errors ? parceErr(err)?.errors : {};
        setformData({
          ...formData,
          error: errText,
          errors: inputErr,
          loader: { status: "بشهمثي" },
        });
        setopenFeedBackMessage({
          status: true,
          isSucsess: false,
          message: errText,
        });
      });
  };
  return (
    <>
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <div>
        <button onClick={close} className="modal_bg"></button>
        <form onSubmit={handleSubmitNote} className="modal_content p-10 flex flex-col items-center">
          <h5 className="text_active font-bold">إدخال ملحوظة على الطالب</h5>
          <textarea
            value={formData.data.note}
            onChange={(e) => setformData({ ...formData, data: { note: e.target.value } })}
            className="min-h-48 w-80 p-3 my-6"
          />
          <CustomSubmitButton
            loader={formData.loader}
            err={formData?.error}
            width="100%"
            height="47px"
            title=" إضافة الملحوظة"
          />
        </form>
      </div>
    </>
  );
}
AddStudentNoteModal.propTypes = {
  close: PropTypes.func,
  regetStudent: PropTypes.func,
  currentNote: PropTypes.string,
  studentId: PropTypes.string,
};
