import React from "react";

export default function ContentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.7657 7.93948L16.5368 8.89755L16.9076 9.10586C17.6975 9.54966 17.6975 10.6938 16.9076 11.1376L11.877 13.9639C10.7104 14.6194 9.28963 14.6194 8.12295 13.9639L3.09244 11.1376C2.30252 10.6938 2.30252 9.54966 3.09244 9.10586L3.46321 8.89755L5.13612 7.93948M15.1509 12.2052L16.7608 13.0155C17.591 13.4335 17.6177 14.6166 16.8071 15.072L11.877 17.8418C10.7104 18.4973 9.28963 18.4973 8.12295 17.8418L3.26483 15.1124C2.44033 14.6491 2.48576 13.4399 3.34266 13.0407L5.13612 12.2052M11.877 9.84272L16.9076 7.01642C17.6975 6.57262 17.6975 5.42846 16.9076 4.98466L11.877 2.15836C10.7104 1.50288 9.28963 1.50288 8.12295 2.15836L3.09244 4.98466C2.30252 5.42846 2.30252 6.57262 3.09244 7.01642L8.12295 9.84272C9.28963 10.4982 10.7104 10.4982 11.877 9.84272Z"
        stroke="#4D4D4D"
        strokeWidth="1.25"
      />
    </svg>
  );
}
