import React from "react";

export default function FaceBookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <g clipPath="url(#clip0_1521_10711)">
        <path
          d="M15 28C16.7072 28 18.3977 27.6637 19.9749 27.0104C21.5521 26.3571 22.9852 25.3995 24.1924 24.1924C25.3995 22.9852 26.3571 21.5521 27.0104 19.9749C27.6637 18.3977 28 16.7072 28 15C28 13.2928 27.6637 11.6023 27.0104 10.0251C26.3571 8.44788 25.3995 7.01477 24.1924 5.80761C22.9852 4.60045 21.5521 3.64288 19.9749 2.98957C18.3977 2.33625 16.7072 2 15 2C11.5522 2 8.24558 3.36964 5.80761 5.80761C3.36964 8.24558 2 11.5522 2 15C2 18.4478 3.36964 21.7544 5.80761 24.1924C8.24558 26.6304 11.5522 28 15 28ZM15 28V12.1111C15 11.3449 15.3044 10.6101 15.8461 10.0684C16.3879 9.52659 17.1227 9.22222 17.8889 9.22222H19.3333M18.6111 15.7222H11.3889"
          stroke="#4271FF"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1521_10711">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
