import React from "react";

export default function OverViewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 19C2.45 19 1.979 18.804 1.587 18.412C1.195 18.02 0.999335 17.5493 1 17V7C1 6.45 1.196 5.979 1.588 5.587C1.98 5.195 2.45067 4.99934 3 5H13C13.55 5 14.021 5.196 14.413 5.588C14.805 5.98 15.0007 6.45067 15 7V17C15 17.55 14.804 18.021 14.412 18.413C14.02 18.805 13.5493 19.0007 13 19H3ZM3 17H13V7H3V17ZM17 19V5H19V19H17ZM21 19V5H23V19H21Z"
        fill="white"
      />
    </svg>
  );
}
