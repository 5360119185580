import React from "react";

export default function LogOutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        d="M7 15V17C7 19.2091 8.79086 21 11 21H16C18.2091 21 20 19.2091 20 17V5C20 2.79086 18.2091 1 16 1H11C8.79086 1 7 2.79086 7 5V7"
        stroke="#F5F7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4 14L1.70711 11.7071C1.31658 11.3166 1.31658 10.6834 1.70711 10.2929L4 8"
        stroke="#F5F7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2 11L14 11"
        stroke="#F5F7FA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
