import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import CompleteDataWorning from "./CompleteDataWorning";
export default function SideBar({ getTeacherGenralData, TapBarData }) {
  const location = useLocation();
  const [renderSideBar, setrenderSideBar] = useState(false);
  const [currentPath, setcurrentPath] = useState(window.location.pathname);
  const [isMobileWidth, setisMobileWidth] = useState(
    window.innerWidth > 768 ? false : true
  );
  // handle append mopile stylle to side bar when width changed
  useEffect(() => {
    const handleResize = () => {
      setisMobileWidth(window.innerWidth > 768 ? false : true);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // handle render or non render sidebar when location changed
  useEffect(() => {
    setcurrentPath(location.pathname);
    checkIfPageHaveSideBar() ? setrenderSideBar(true) : setrenderSideBar(false);
  }, [location]);
  // handle update name and packedge (genral data) when rerender side bar
  useEffect(() => {
    if (renderSideBar) {
      getTeacherGenralData();
    }
  }, [renderSideBar]);
  return (
    <>
      {renderSideBar && (
        <>
          <div>
            <TopNav isMobileWidth={isMobileWidth} TapBarData={TapBarData} />
            {(TapBarData.data?.unCompletedApplicationData ||
              TapBarData.data?.unCompletedProfileData) && (
              <CompleteDataWorning
                profileWorning={TapBarData.data?.unCompletedProfileData}
                appWoorning={TapBarData.data?.unCompletedApplicationData}
              />
            )}
          </div>
          <SideNav currentPath={currentPath} isMobileWidth={isMobileWidth} />
        </>
      )}
    </>
  );
}
SideBar.propTypes = {
  getTeacherGenralData: PropTypes.func,
  TapBarData: PropTypes.object,
};
const checkIfPageHaveSideBar = () => {
  let noneSidePagesPaths = [
    "signup",
    "log-in",
    "reset-password",
    "otp",
    "complete-information",
  ];
  let falg = noneSidePagesPaths.some((path) =>
    window.location.pathname.includes(path)
  );
  return !falg;
};
