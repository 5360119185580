import React from "react";

export default function AddVideoIcon() {
  return (
    <div className="relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="36"
        viewBox="0 0 44 36"
        fill="none"
      >
        <path
          d="M31.8594 2.4397H11.8594C6.33653 2.4397 1.85938 6.91569 1.85938 12.4371V24.434C1.85938 29.9554 6.33653 34.4314 11.8594 34.4314H31.8594C37.3822 34.4314 41.8594 29.9554 41.8594 24.434V12.4371C41.8594 6.91569 37.3822 2.4397 31.8594 2.4397Z"
          stroke="#1D68DF"
          strokeWidth="2.99961"
        />
        <path
          d="M29.8828 18.4362L17.8828 26.4341V10.4382L29.8828 18.4362Z"
          stroke="#1D68DF"
          strokeWidth="2.99961"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        className="absolute right-0 bottom-0 bg-white "
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M6.78516 1.98981L6.78516 12.1017"
          stroke="#1D68DF"
          strokeWidth="2.5283"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.73316 7.04578L11.8477 7.04578"
          stroke="#1D68DF"
          strokeWidth="2.5283"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
