import React, { useEffect, useState } from "react";
import CustomInput from "../../components/reuseables/CustomInput";
import CustomSubmitButton from "../../components/reuseables/CustomSubmitButton";
import GoalsInputs from "../../components/pagesComponents/courses/GoalsInputs";
import SelectFromCheckBox from "../../components/reuseables/SelectFromCheckBox";
import CustomUploadeInput from "../../components/reuseables/CustomUploadeInput";
import {
  checkRequired,
  compareObjects,
  removeEmptyValues,
} from "../../globalFunctions/validation";
import SucsessMessage from "../../components/reuseables/SucsessMessage";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import { useParams } from "react-router-dom";
import ResultMessage from "../../components/reuseables/ResultMessage";
export default function AddCourse() {
  const prams = useParams();
  const [CourseOldData, setCourseOldData] = useState();
  const [formData, setformData] = useState({
    data: { name: "", goals: ["", ""], categories: [] },
    submitLoader: { status: "" },
    getOldDataLoader: {
      status: window.location.pathname.includes("edit") ? "loading" : "done",
    },
    errors: {},
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleGetCourseDetailes = () => {
    if (formData.getOldDataLoader?.status !== "loading") {
      setformData({
        ...formData,
        errors: {},
        error: "",
        getOldDataLoader: { status: "loading", text: "" },
      });
    }
    myFeach(`courses/${prams.id}?content=false`, "GET", {}, "token")
      .then(({ data }) => {
        setformData({
          submitLoader: { status: "" },
          errors: {},
          error: "",
          data: {
            ...data.course,
            goals:
              data.course.goals.length > 0 ? [...data.course.goals] : ["", ""],
          },
          getOldDataLoader: { status: "done" },
        });
        setCourseOldData(data.course);
      })
      .catch((err) => {
        setformData({
          ...formData,
          getOldDataLoader: {
            status: "failed",
            text: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل تحميل تفاصيل الكورس",
          },
        });
      });
  };
  const handleAddCourse = () => {
    myFeach("courses", "POST", removeEmptyValues(formData.data), "token")
      .then((data) => {
        console.log(data);
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: "تم إضافة الكورس بنجاح",
        });
        setformData({
          ...formData,
          data: {
            goals: ["", "", ""],
            name: "",
            price: "",
            discountedPrice: "",
            categories: [],
            image: "",
            description: "",
          },
          errors: {},
          error: "",
        });
      })
      .catch((err) => {
        setformData({
          ...formData,
          loader: { status: "done" },
          errors: parceErr(err)?.errors,
          error: parceErr(err)?.error
            ? parceErr(err)?.error
            : "فشل إضافة الكورس",
        });
        setopenFeedBackMessage({
          status: true,
          message: parceErr(err)?.error
            ? parceErr(err)?.error
            : "فشل إضافة الكورس",
        });
      });
  };
  const handleEdieCourse = () => {
    let changedFileds = compareObjects(formData.data, CourseOldData);
    let finalObject;
    changedFileds.goals
      ? (finalObject = {
          ...changedFileds,
          goals: changedFileds.goals.filter((elem) => elem != ""),
        })
      : (finalObject = { ...changedFileds });
    myFeach(`courses/${prams.id}`, "PUT", finalObject, "token")
      .then(() => {
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: "تم تعديل الكورس بنجاح",
        });
        handleGetCourseDetailes();
      })
      .catch((err) => {
        setopenFeedBackMessage({
          status: true,
          message: parceErr(err)?.error
            ? parceErr(err)?.error
            : "فشل تعديل البيانات حاول مرة أخرى",
        });
        setformData({
          ...formData,
          errors: parceErr(err).errors,
          error: parceErr(err)?.error
            ? parceErr(err)?.error
            : "فشل تعديل البيانات حاول مرة أخرى",
        });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let requiredErrs = checkRequired(
      formData.data,
      ["name", "price", "image"],
      formData,
      setformData
    );
    if (requiredErrs === "sucsess") {
      setformData({
        ...formData,
        submitLoader: { status: "loading" },
        error: "",
        errors: {},
      });
      if (window.location.pathname.includes("edit")) {
        handleEdieCourse();
      } else {
        handleAddCourse();
      }
    }
  };
  useEffect(() => {
    if (prams?.id) {
      handleGetCourseDetailes();
    }
  }, []);
  return (
    <main>
      {openFeedBackMessage?.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      {window.location.pathname.includes("edit") ? (
        <h2 className="font-bold text-3xl">تعديل الكورس</h2>
      ) : (
        <h2 className="font-bold text-3xl">إضافة كورس جديد</h2>
      )}
      <h6 className="mt-2"  >تأكد من مليء الحقول التي تحتوي على رمز <span className="text_danger" >*</span></h6 >
      {formData.getOldDataLoader?.status === "failed" ? (
        <ResultMessage message={formData.getOldDataLoader?.text} />
      ) : (
        <form className="mt-10 md:w-10/12 " onSubmit={handleSubmit}>
          <CustomInput
            width="100%"
            lable={"اسم الكورس"}
            err={false}
            required={true}
            name="name"
            setformData={setformData}
            formData={formData}
            containerClass="w-full md:w-3/4"
            getOldDataLoader={formData.getOldDataLoader?.status}
          />
          <div className="flex flex-wrap justify-between my-8 ">
            <CustomInput
              width="100%"
              lable={"سعر الكورس بالكامل"}
              err={false}
              required={true}
              placeHolder="بالريال السعودي"
              type="number"
              lang="en"
              name="price"
              setformData={setformData}
              formData={formData}
              containerClass="w-full md:w-7/12 md:pl-6"
              getOldDataLoader={formData.getOldDataLoader?.status}
            />
            <CustomInput
              width="100%"
              lable={"سعر الكورس بعد الخصم"}
              placeHolder="بالريال السعودي"
              err={false}
              type="number"
              lang="en"
              name="discountedPrice"
              setformData={setformData}
              formData={formData}
              containerClass="w-full md:w-5/12"
              getOldDataLoader={formData.getOldDataLoader?.status}
            />
          </div>
          <CustomUploadeInput
            apiUrl="courseImage"
            required={true}
            name="image"
            formData={formData}
            setformData={setformData}
            disabled={formData.submitLoader.status === "loading" ? true : false}
            lable="صورة الكورس"
            // btn_class="px-24 py-16 w-20 h-20"
            getOldDataLoader={formData.getOldDataLoader?.status}
          />
          <SelectFromCheckBox
            lable=" تخصصات الكورس"
            required={false}
            disabled={formData.submitLoader.status === "loading" ? true : false}
            formData={formData}
            setformData={setformData}
            objectKey={"categories"}
            apiUrl="categories"
            getOldDataLoader={formData.getOldDataLoader?.status}
          />
          <CustomInput
            width="100%"
            height="120px"
            lable={"وصف الكورس"}
            err={false}
            type="textArea"
            name="description"
            setformData={setformData}
            formData={formData}
            containerClass="w-full mt-8"
            getOldDataLoader={formData.getOldDataLoader?.status}
          />
          <GoalsInputs
            getOldDataLoader={formData.getOldDataLoader?.status}
            formData={formData}
            setformData={setformData}
          />
          <CustomSubmitButton
            loader={formData.submitLoader}
            err={formData?.error}
            disabled={
              formData.getOldDataLoader.status !== "done" ? true : false
            }
            width="100%"
            height="47px"
            title={
              window.location.pathname.includes("edit")
                ? "تعديل الكورس"
                : "تأكيد الإضافة"
            }
          />
        </form>
      )}
    </main>
  );
}
