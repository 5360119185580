import React from "react";
import PropTypes from "prop-types";

export default function SelectAppColor({
  onchange,
  selectedColor = "",
  err,
  getOldDataLoader = "done",
}) {
  return (
    <div>
      {getOldDataLoader === "done" ? (
        <>
          <h6 className="mb-2 flex items-center">
            <span className="font-bold">اللون </span>
            <span>(اختر أحد الألوان) </span>{" "}
            <span className="text-red-500 text-xl" >*</span>
          </h6>
          <div className="flex p-2 bg-white w-fit my_rounded ">
            {Object.keys(colors).map((colorList, index) => (
              <div className="flex-col" key={index}>
                {colors[colorList].map((elem, index) => (
                  <button
                    type="button"
                    onClick={() => onchange(elem)}
                    key={index}
                    className={` w-fit h-fit circle_rounded m-1 border-white border-2 block   hover:border-gray-300 hover:border-2  p-0.5 ${selectedColor == elem ? "selected_Color" : ""}`}
                  >
                    <div
                      className="circle_rounded w-6 h-6 "
                      style={{ backgroundColor: elem }}
                    ></div>
                  </button>
                ))}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="my_rounded bg-white w-60 h-60"></div>
      )}
      <h6 className="text-red-400">{err}</h6>
    </div>
  );
}
SelectAppColor.propTypes = {
  onchange: PropTypes.func,
  err: PropTypes.string,
  selectedColor: PropTypes.string,
  getOldDataLoader: PropTypes.string,
};
let colors = {
  blue: ["#55C6F6", "#0099FF", "#1D48DF", "#001DAF", "#1D1F63"],
  greenColors: ["#20DFD9", "#00B8A5", "#359B9B", "#177082", "#004B52"],
  orangeColors: ["#E4BD81", "#E88B00", "#EC542C", "#8B5504", "#4E340E"],
  pinkColors: ["#F76E86", "#D82671", "#7E23B6", "#8E016F", "#602055"],
  red: ["#D78875", "#D60000", "#8A0F0F", "#787272", "#1E2124"],
};
