import React from "react";

export default function ImageIcon() {
  return (
    <div className="relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
      >
        <rect
          x="1.73438"
          y="1.73779"
          width="37.0588"
          height="37.0588"
          rx="9.26471"
          stroke="#1D68DF"
          strokeWidth="2.77941"
        />
        <path
          d="M2.66406 30.4582L6.85022 27.4681C8.17695 26.5204 9.99435 26.6708 11.1472 27.8237L13.1359 29.8124C14.0042 30.6807 15.4121 30.6807 16.2805 29.8124L25.525 20.5678C26.7448 19.348 28.6934 19.2596 30.0186 20.364L38.7964 27.6788"
          stroke="#1D68DF"
          strokeWidth="2.77941"
          strokeLinecap="round"
        />
        <circle
          cx="3.70588"
          cy="3.70588"
          r="3.70588"
          transform="matrix(-1 0 0 1 16.5547 9.14941)"
          stroke="#1D68DF"
          strokeWidth="2.77941"
        />
      </svg>
      <div className="absolute right-0 bottom-0 bg-white ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M7.44141 2.24162L7.44141 12.6455"
            stroke="#1D68DF"
            strokeWidth="2.60097"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.24064 7.4436L12.6445 7.4436"
            stroke="#1D68DF"
            strokeWidth="2.60097"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}
