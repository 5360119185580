import React from "react";

export default function SubscreptionsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
    >
      <rect
        x="7.66797"
        y="3.91626"
        width="10.6667"
        height="15.2093"
        rx="2.5"
        stroke="white"
      />
      <path
        d="M10.3359 6.07471H15.6693"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M10.3359 6.07471V9.40804"
        stroke="white"
        strokeLinecap="round"
      />
      <path d="M15.668 6.07471V9.40804" stroke="white" strokeLinecap="round" />
      <path
        d="M10.3359 9.40796H15.6693"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M10.3359 13.408H15.6693"
        stroke="white"
        strokeWidth="0.7"
        strokeLinecap="round"
      />
      <path
        d="M10.3359 15.408H15.6693"
        stroke="white"
        strokeWidth="0.7"
        strokeLinecap="round"
      />
      <path
        d="M14.332 3.91662V3.37476C14.332 1.99404 15.4513 0.874756 16.832 0.874756H22.4987C23.8794 0.874756 24.9987 1.99404 24.9987 3.37476V13.5841C24.9987 14.9648 23.8794 16.0841 22.4987 16.0841H18.332"
        stroke="white"
      />
      <path
        d="M17 3.40845L22.3333 3.40845"
        stroke="white"
        strokeLinecap="round"
      />
      <path d="M22.332 3.40845V6.74178" stroke="white" strokeLinecap="round" />
      <path
        d="M18.332 6.74146L22.332 6.74146"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M18.332 10.7415L22.332 10.7415"
        stroke="white"
        strokeWidth="0.7"
        strokeLinecap="round"
      />
      <path
        d="M18.332 12.7415L22.332 12.7415"
        stroke="white"
        strokeWidth="0.7"
        strokeLinecap="round"
      />
      <path
        d="M7.66667 16.0838H3.5C2.11929 16.0838 1 14.9645 1 13.5838V3.37451C1 1.9938 2.11929 0.874512 3.5 0.874512H9.16666C10.5474 0.874512 11.6667 1.9938 11.6667 3.37451V3.91637"
        stroke="white"
      />
      <path d="M3.66797 3.4082H9.00397" stroke="white" strokeLinecap="round" />
      <path d="M3.66797 3.4082V6.74154" stroke="white" strokeLinecap="round" />
      <path
        d="M3.66797 6.74121H7.66797"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M3.66797 10.7412H7.66797"
        stroke="white"
        strokeWidth="0.7"
        strokeLinecap="round"
      />
      <path
        d="M3.66797 12.7412H7.66797"
        stroke="white"
        strokeWidth="0.7"
        strokeLinecap="round"
      />
    </svg>
  );
}
