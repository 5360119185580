import React from "react";

export default function DangerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
    >
      <path
        d="M1 15.9261C1 15.3187 1.15479 14.7214 1.44975 14.1904L7.63566 3.0558C8.18399 2.06881 9.13806 1.37239 10.2452 1.15096V1.15096C10.7435 1.05131 11.2565 1.05131 11.7548 1.15096V1.15096C12.8619 1.37239 13.816 2.06881 14.3643 3.05581L20.5502 14.1904C20.8452 14.7214 21 15.3187 21 15.9261V15.9261C21 17.8999 19.3999 19.5 17.4261 19.5H4.57391C2.60009 19.5 1 17.8999 1 15.9261V15.9261Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M11 7L11 11"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 14L11 14.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
