import React, { useState } from "react";
import PropTypes from "prop-types";
import DesafeLogo from "../icons/DesafeLogo";
import DesafeTextLogo from "../icons/DesafeTextLogo";
import LogOutIcon from "../icons/LogOutIcon";
import { Link, useNavigate } from "react-router-dom";
import PersonsIcon from "../icons/PersonsIcon";
import SubscreptionsIcon from "../icons/SubscreptionsIcon";
import QuestionIcon from "../icons/QuestionIcon";
import StatsticsIcon from "../icons/StatsticsIcon";
import SettingIcon from "../icons/SettingIcon";
import OverViewIcon from "../icons/OverViewIcon";
import CoursesIcon from "../icons/CoursesIcon";
import RequestsIcon from "../icons/RequestsIcon";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import SucsessMessage from "../reuseables/SucsessMessage";
import Cookies from "js-cookie";
export default function SideNav({ isMobileWidth, currentPath }) {
  const navigate = useNavigate();
  const [logOutLoader, setlogOutLoader] = useState("");
  function checkStringInArray(arr) {
    if (currentPath === "/" && arr[0] == "over-view") {
      return true;
    } else {
      return arr.some((element) => currentPath.includes(element));
    }
  }
  const logOut = () => {
    setlogOutLoader({ status: "loading" });
    myFeach("logout", "POST", {}, "token")
      .then((data) => {
        console.log(data);
        setlogOutLoader({ status: "done" });
        setTimeout(() => {
          Cookies.remove("token");
          navigate("log-in");
        }, 2050);
      })
      .catch((err) => {
        let errText = parceErr(err).error
          ? parceErr(err)?.error
          : "فشل تسجيل الخروج";
        setlogOutLoader({ status: "failed", text: errText });
        console.log(err);
      });
  };
  return (
    <>
      {(logOutLoader.status === "failed" || logOutLoader.status === "done") && (
        <SucsessMessage
          isSucsess={logOutLoader.status === "done" ? true : false}
          message={
            logOutLoader.status == "done"
              ? "تم تسجيل الخروج بنجاح"
              : "فشل تسجيل الخروج"
          }
          close={() => setlogOutLoader({ status: "" })}
        />
      )}
      <div
        id="nav_list"
        className={`side_bar pb-8 hide_scoller  ${isMobileWidth ? "collapse_Side" : ""}`}
      >
        <figure className="w-full bg-white m-auto flex justify-center items-center h-20 logo side_logo  ">
          <DesafeLogo />
          <div className="text_logo">
            <DesafeTextLogo />
          </div>
        </figure>
        <div className="mt-5 side_bar_items">
          {sideBarItems.map((sidebarItem, index) => (
            <Link
              key={index}
              className={`flex pr-10 mb-2 py-3 w-3/4 items-center list pl-4 ${
                checkStringInArray(sidebarItem.hilights) ? "active" : ""
              }`}
              to={sidebarItem.path}
            >
              {sidebarItem.icon()}
              <h4 className="text-white font-normal pr-5 nav_title w-44 ">
                {sidebarItem.title}
              </h4>
            </Link>
          ))}
          <div
            style={{ height: "1px" }}
            className="w-full my-3  bg-white"
          ></div>
          <div className="pt-10">
            <button
              onClick={logOut}
              className="text-white m-auto w-fit  flex  items-center flex-wrap justify-center"
            >
              {logOutLoader.status === "loading" ? (
                <div className="loader"></div>
              ) : (
                <LogOutIcon />
              )}
              <h6 className="block mx-1 ">تسجيل الخروج</h6>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
SideNav.propTypes = {
  isMobileWidth: PropTypes.bool,
  currentPath: PropTypes.string,
};
const sideBarItems = [
  {
    title: "نظرة عامة",
    hilights: ["over-view"],
    path: "/",
    icon: () => <OverViewIcon />,
  },
  {
    title: "الكورسات",
    hilights: [
      "courses",
      "course-detailes",
      "add-course",
      "add-lesson",
      "edit-lesson",
      "edit-course",
    ],
    path: "/courses",
    icon: () => <CoursesIcon />,
  },
  {
    title: "طلبات الطلبة",
    hilights: ["requests"],
    path: "/requests",
    icon: () => <RequestsIcon />,
  },
  {
    title: "الطلبة",
    hilights: ["students", "student"],
    path: "/students",
    icon: () => <PersonsIcon />,
  },
  {
    title: "الاشتراكات",
    hilights: ["subscreptions"],
    path: "/subscreptions",
    icon: () => <SubscreptionsIcon />,
  },
  {
    title: "الأسئلة",
    hilights: ["questions"],
    path: "/questions",
    icon: () => <QuestionIcon />,
  },
  {
    title: "الإحصائيات",
    hilights: ["statstics"],
    path: "/statstics",
    icon: () => <StatsticsIcon />,
  },
  {
    title: "الإعدادات",
    hilights: ["setting"],
    path: "/setting",
    icon: () => <SettingIcon />,
  },
];
