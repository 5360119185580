import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import InputScaleable from "./InputScaleable";
export default function SelectFromCheckBox({
  apiUrl,
  objectKey,
  formData,
  setformData,
  disabled,
  lable,
  required,
  staticList,
  getOldDataLoader = "done",
}) {
  const [list, setlist] = useState({
    list: staticList ? [...staticList] : [],
    loader: { status: apiUrl ? "loading" : "done", text: "" },
    err: "",
  });
  const getDinamicList = () => {
    myFeach(apiUrl, "GET", {}, "token")
      .then(({ data }) => {
        setlist({ list: data.categories, loader: { status: "done" } });
      })
      .catch((err) => {
        console.log(parceErr(err));
        setlist({
          ...list,
          loader: { status: "failed" },
          err: parceErr(err).error,
        });
      });
  };
  const handleOnChange = (e) => {
    let selectedElement = e.target.value;
    if (formData?.data[objectKey]?.includes(selectedElement)) {
      setformData({
        ...formData,
        data: {
          ...formData.data,
          [objectKey]: formData.data[objectKey].filter(
            (elem) => elem != selectedElement
          ),
        },
      });
    } else {
      setformData({
        ...formData,
        data: {
          ...formData.data,
          [objectKey]: [...formData.data[objectKey], selectedElement],
        },
      });
    }
  };
  useEffect(() => {
    // handle get list after get old data to prevent conflict
    if (getOldDataLoader == "done") {
      apiUrl && getDinamicList();
    }
  }, [getOldDataLoader]);
  return (
    <>
      {getOldDataLoader === "done" ? (
        <div className="mb-8">
          <label className="text-sm font-bold">
            {lable} {required && <span className="text_danger">*</span>}{" "}
          </label>
          <div className="flex flex-wrap mt-4">
            {list.loader.status === "done" && (
              <>
                {list.list.map((option, index) => (
                  <div key={index} className="flex items-center ml-5">
                    <input
                      checked={
                        formData.data[objectKey]?.includes(option.id)
                          ? true
                          : false
                      }
                      disabled={disabled}
                      value={option?.id}
                      type="checkbox"
                      className="ml-2 mb-2 w-5 h-5"
                      onChange={handleOnChange}
                    />
                    <label>{option.name}</label>
                  </div>
                ))}
              </>
            )}
            {list.loader.status === "loading" && (
              <div className="dot_loader mx-6"></div>
            )}
            {list.loader.status === "failed" && (
              <h4 className="text-xs text_danger">{list?.err}</h4>
            )}
          </div>
          <h4 className="text-xs text_danger">{formData?.errors[objectKey]}</h4>
        </div>
      ) : (
        <InputScaleable width={"100%"} height="40px" />
      )}
    </>
  );
}
SelectFromCheckBox.propTypes = {
  apiUrl: PropTypes.string,
  lable: PropTypes.string,
  objectKey: PropTypes.string,
  formData: PropTypes.object,
  staticList: PropTypes.array,
  setformData: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getOldDataLoader: PropTypes.string,
};
