import React, { useState } from "react";
import PropTypes from "prop-types";
import ButtonArrow from "../../icons/ButtonArrow";
import TopArrow from "../../icons/TopArrow";
import ContentIcon from "../../icons/ContentIcon";
import EditIcon from "../../icons/EditIcon";
import { Link, useParams } from "react-router-dom";
import VideoIcon from "../../icons/VideoIcon";
import DocumentIcon from "../../icons/DocumentIcon";
import ShowIcon from "../../icons/ShowIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import HideCourse from "./HideCourse";
import AddSectionModal from "./AddSectionModal";
import HideIcon from "../../icons/HideIcon";
import DeleteCourse from "./DeleteCourse";
export default function CourseSection({
  sectionData,
  index,
  regetCourseDetailes,
}) {
  const prams = useParams();
  const [showLesons, setshowLesons] = useState(index == 0 ? true : false);
  const [openHideLesonModal, setopenHideLesonModal] = useState({
    status: false,
    targetStatus: "",
    type: "",
  });
  const [openDeleteLesonModal, setopenDeleteLesonModal] = useState({
    status: false,
    targetStatus: "",
    type: "",
  });
  const [openEditSectionModal, setopenEditSectionModal] = useState({
    status: false,
    id: "",
  });

  return (
    <>
      {openHideLesonModal.status && (
        <HideCourse
          targetStatus={openHideLesonModal.targetStatus}
          close={() => setopenHideLesonModal({ status: false })}
          type={openHideLesonModal?.type}
          id={openHideLesonModal.id}
          cardDetailes={openHideLesonModal.leson}
          regetCourseDetailes={regetCourseDetailes}
        />
      )}
      {openDeleteLesonModal.status && (
        <DeleteCourse
          targetStatus={openDeleteLesonModal.targetStatus}
          close={() => setopenDeleteLesonModal({ status: false })}
          type={openDeleteLesonModal?.type}
          id={openDeleteLesonModal.id}
          cardDetailes={openDeleteLesonModal.leson}
          regetCourseDetailes={regetCourseDetailes}
        />
      )}
      {openEditSectionModal.status === true && (
        <AddSectionModal
          regetCourseDetailes={regetCourseDetailes}
          close={() => setopenEditSectionModal({ status: false, id: "" })}
          sectionData={openEditSectionModal.sectionData}
        />
      )}
      <div className="w-full my_rounded my-10 ">
        <div className="bg_title top_rounded py-3 px-4 flex justify-between ">
          <h4 className="text_white"> القسم {index + 1}</h4>
          <button onClick={() => setshowLesons(!showLesons)}>
            {showLesons ? <TopArrow /> : <ButtonArrow />}
          </button>
        </div>
        {showLesons && (
          <div className="w-full">
            <div className="bg_greay w-full py-4 px-4 flex justify-between flex-wrap">
              <div className="flex items-center flex-wrap">
                <div className="flex items-center">
                  <ContentIcon />
                  <h5 className="mx-3 md:text-lg text-sm ">
                    {sectionData.name}
                  </h5>
                </div>
                <button
                  className="my-2 md:my-0 flex "
                  onClick={() =>
                    setopenEditSectionModal({
                      status: true,
                      sectionData: sectionData,
                    })
                  }
                >
                  <EditIcon />
                </button>
              </div>
              <div className="flex flex-wrap ">
                <h5 className="">{sectionData?.noOfStudents} طالب</h5>
                {!sectionData?.discountedPrice ? (
                  <h5 className="font-bold text_sucsess mx-3">
                    {sectionData?.price} ريال
                  </h5>
                ) : (
                  <div className="flex">
                    <h5 className="font-bold text_danger line-through mx-3 ">
                      {sectionData?.price} ريال
                    </h5>
                    <h5 className="font-bold text_sucsess">
                      {sectionData?.discountedPrice} ريال
                    </h5>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className=" justify-between py-3 px-4 hide_in_mop md:flex ">
                <h4 className="font-bold">الدروس</h4>
                <h4 className="font-bold w-20 text-center ">الرؤية</h4>
              </div>
              <div className="w-full shadow-md">
                {sectionData.lessons.length ? (
                  <>
                    {sectionData.lessons.map((leson, index) => (
                      <div
                        className={`w-full flex-col md:flex-row flex justify-between ${index != sectionData.lessons.length - 1 ? "border_buttom_greay" : ""}   `}
                        key={index}
                      >
                        <div className="flex-col md:flex-row items-center px-4 py-4  justify-between w-full flex-wrap  flex">
                          <div className="flex items-center flex-wrap ">
                            <h4 className="ml-4">{leson.name}</h4>
                            {leson.videoExists && (
                              <div className="mx-1">
                                <VideoIcon iconColor="greay" />
                              </div>
                            )}
                            {leson.pdfExists && (
                              <div className="mx-1">
                                <DocumentIcon iconColor="greay" />
                              </div>
                            )}
                          </div>
                          <div className="flex my-3 md:my-0 ">
                            <Link
                              to={`/edit-lesson/${prams.id}/${prams.name}/${sectionData.id}/${decodeURIComponent(sectionData.name)}/${leson.id}`}
                            >
                              <EditIcon />
                            </Link>
                            <button
                              className="mx-3"
                              onClick={() =>
                                setopenDeleteLesonModal({
                                  status: true,
                                  id: leson.id,
                                  type: "leson",
                                  leson: leson,
                                  targetStatus: leson.hidden ? "show" : "hide",
                                })
                              }
                            >
                              <DeleteIcon />
                            </button>
                          </div>
                        </div>
                        <button
                          className="flex items-center justify-center md:w-28  md:pl-4 py-2  md:py-4"
                          onClick={() =>
                            setopenHideLesonModal({
                              status: true,
                              id: leson.id,
                              type: "leson",
                              leson: leson,
                              targetStatus: leson.hidden ? "show" : "hide",
                            })
                          }
                        >
                          {leson?.hidden ? (
                            <HideIcon stroke="black" />
                          ) : (
                            <ShowIcon color="#4271ff" />
                          )}
                        </button>
                      </div>
                    ))}
                  </>
                ) : (
                  <h6 className="text_danger w-full text-center py-4">
                    لا يوجد دروس بعد
                  </h6>
                )}
              </div>
            </div>
            <Link
              className="text_secondary mt-6 h4 inline-block"
              to={`/add-lesson/${prams.id}/${prams.name}/${sectionData.id}/${decodeURIComponent(sectionData.name)}`}
            >
              + إضافة درس جديد
            </Link>
          </div>
        )}
      </div>
    </>
  );
}
CourseSection.propTypes = {
  sectionData: PropTypes.object,
  index: PropTypes.number,
  regetCourseDetailes: PropTypes.func,
};
