import React from "react";
import PropTypes from "prop-types";

export default function PlanCard({ cardData, loader, duration }) {
  return (
    <>
      {loader == "loading" ? (
        <div className="w-full md:w-1/5 h-80 bg-white my_rounded mx-2 flex flex-col items-center p-3">
          <div className="w-20 h-20  my_rounded bg_greay"></div>
          <h5 className="h-4 w-20 mt-1 bg_greay"></h5>
          <h5 className="h-10 w-full mt-4 bg_greay"></h5>
          <h5 className="h-10 w-full mt-2 bg_greay"></h5>
          <h5 className="h-10 w-full mt-2 bg_greay"></h5>
        </div>
      ) : (
        <div className="bg-white w-full md:w-1/5 my_rounded mx-2 p-3 flex flex-col mb-4 ">
          <figure
            style={{
              backgroundColor: cardData.color ? cardData.color : "#B44AFF",
            }}
            className="my_rounded w-20 min-h-20 p-2 mb-2 mx-auto flex items-center justify-center "
          >
            <img className="block w-full" src={cardData.logo} />
          </figure>
          <h5 className="font-bold mx-auto">{cardData.name}</h5>
          <h5 className="mx-auto">اشتراك {duration}</h5>
          {cardData.price.discounted ? (
            <>
              <h5 className="text-red-500 line-through mx-auto">
                {cardData.price.normal} دولار / {duration}
              </h5>
              <h5 className="mx-auto">
                {cardData.price.discounted} دولار / {duration}
              </h5>
            </>
          ) : (
            <h5 className="mx-auto">
              {cardData.price.normal} دولار / {duration}
            </h5>
          )}
          <h5 className="mt-10 font-bold">محتويات الباقة</h5>
          <h5 className="mt-2 mx-auto">
            مساحة التخزين:
            <span className="font-bold">{cardData.storage} GB</span>{" "}
          </h5>
          <h5 className="mt-2 mx-auto">
            عدد الطلاب:
            <span className="font-bold">{cardData.students} طالب</span>{" "}
          </h5>
          <h5 className="mt-2 mx-auto">
            عدد الكورسات:
            <span className="font-bold">{cardData.courses} كورس</span>{" "}
          </h5>
          <ul className="mt-6 mx-auto">
            {cardData?.characteristics.map((elem, index) => (
              <li key={index} className="dot_list mr-5">
                {elem}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
PlanCard.propTypes = {
  cardData: PropTypes.any,
  loader: PropTypes.string,
  duration: PropTypes.string,
};
