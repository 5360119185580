import React, { useState } from "react";
import PropTypes from "prop-types";
import agreeIcon from "../../../assets/icons/agree 1.png";
import disssAgreeIcon from "../../../assets/icons/disagree 1.png";
import SucsessMessage from "../../reuseables/SucsessMessage";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
export default function SubPermesion({
  permesion,
  permessionKey,
  getPermesions,
}) {
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const [loader, setloader] = useState("");
  const handleUpgrade = (permessionKey) => {
    setloader("loading");
    myFeach(
      "teachers/application-settings?data=permissions",
      "PUT",
      { [permessionKey]: { currentValue: !permesion.currentValue } },
      "token"
    )
      .then((data) => {
        console.log(data);
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: "تم تغير حالة الصلاحية بنجاح",
        });
        setloader("");
        setTimeout(() => {
          getPermesions();
        }, 1500);
      })
      .catch((err) => {
        let errText = parceErr(err).error
          ? parceErr(err).error
          : "فشل تغير حالة الصلاحية ";
        setopenFeedBackMessage({
          status: true,
          message: errText,
        });
        setloader("");
      });
  };
  return (
    <>
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <li className="  py-2 botom_border flex flex-wrap items-center justify-between ">
        <div>
          <div className="flex flex-wrap items-center ">
            <div className="w-10 h-10 ml-3">
              <div
                className=" imge_bg scale-75"
                style={{
                  backgroundImage: `url(${permesion.currentValue ? agreeIcon : disssAgreeIcon})`,
                }}
              ></div>
            </div>
            <span> {permesion.arabicName}</span>
          </div>
        </div>
        <>
          <div className="flex flex-wrap items-center">
            <h6 className="font-normal md:mx-4 md:my-0 my-3 mx-0 text-xs">
              {!permesion.currentValue
                ? "إذا كنت ترغب في تفعيل هذه الخاصية"
                : "إذا كنت ترغب في إلغاء تفعيل هذه الخاصية"}
            </h6>
            <button
              disabled={loader == "loading"}
              onClick={() => handleUpgrade(permessionKey)}
              className={` p-1 w-14 my_rounded bg_secondary  ${permesion.currentValue ? "" : "opacity-50"} `}
              style={{ borderRadius: "13px" }}
            >
              <div
                className={`bg-white h-5 w-5 circle_rounded ${permesion.currentValue ? "mr-auto" : "ml-auto"}`}
              >
                {loader === "loading" && <div className="loader "></div>}
              </div>
            </button>
          </div>
        </>
      </li>
    </>
  );
}
SubPermesion.propTypes = {
  permesion: PropTypes.object,
  handleUpgrade: PropTypes.func,
  permessionKey: PropTypes.string,
  getPermesions: PropTypes.func,
};
