import React from "react";
import PropTypes from "prop-types";
// import CustomInput from "../../reuseables/CustomInput";
import RightIcon from "../../icons/RightIcon";
import EscIcon from "../../icons/EscIcon";
import InputScaleable from "../../reuseables/InputScaleable";

export default function GoalsInputs({
  formData,
  setformData,
  getOldDataLoader = "done",
}) {
  const deleteGoal = (goalIndex) => {
    setformData((prevFormData) => {
      const updatedGoals = formData.data.goals.filter(
        (elem, index) => index !== goalIndex
      );

      return {
        ...prevFormData,
        data: {
          ...prevFormData.data,
          goals: updatedGoals,
        },
      };
    });
  };
  const handleOnchange = (e, index) => {
    let myGoals = [...formData.data.goals];
    myGoals[index] = e.target.value;
    setformData({
      ...formData,
      data: { ...formData.data, goals: [...myGoals] },
    });
  };
  return (
    <>
      {getOldDataLoader === "done" ? (
        <>
          <div className="w-full mt-8 ">
            <h3 className="text-sm font-bold">أهداف الكورس</h3>
          </div>
          <div className="w-full flex items-center">
            <div className="bg-blue-600 circle_rounded w-7 h-7 flex items-center justify-center ml-4  scale-90 ">
              <RightIcon />
            </div>
            <div className="w-full my-2">
              <input
                className="w-full"
                value={formData.data.goals[0]}
                onChange={(e) => handleOnchange(e, 0)}
              />
            </div>
          </div>
          {formData.data?.goals.map((goal, goalIndex) => (
            <div key={goalIndex}>
              {goalIndex < formData.data?.goals.length - 1 && (
                <div className="w-full flex items-center" key={goalIndex}>
                  <div className="bg-blue-600 circle_rounded w-7 h-7 flex items-center justify-center ml-4  scale-90 ">
                    <RightIcon />
                  </div>
                  <div className="w-full my-2">
                    <input
                      className="w-full"
                      value={formData.data.goals[goalIndex + 1]}
                      onChange={(e) => handleOnchange(e, goalIndex + 1)}
                    />
                  </div>
                  {goalIndex == formData.data.goals.length - 2 && (
                    <button
                      type="button"
                      className="mr-2"
                      onClick={() => deleteGoal(goalIndex + 1)}
                    >
                      <EscIcon stroke="red" />
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
          <div className="flex flex-wrap justify-between w-full">
            <h5 className="text_danger mr-11 text-xs">{formData?.errors?.goals}</h5>
            <button
              className="text_secondary mr-auto block w-fit mt-3 mb-14"
              type="button"
              onClick={() =>
                setformData({
                  ...formData,
                  data: {
                    ...formData.data,
                    goals: [...formData.data.goals, ""],
                  },
                })
              }
            >
              + إضافة هدف آخر
            </button>
          </div>
        </>
      ) : (
        <InputScaleable width="100%" height="200px" />
      )}
    </>
  );
}
GoalsInputs.propTypes = {
  formData: PropTypes.object,
  getOldDataLoader: PropTypes.string,
  setformData: PropTypes.func,
};
