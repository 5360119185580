import React from "react";
import PropTypes from "prop-types";

export default function InputScaleable({ width, height, styleClass,containerClass }) {
  return (
    <div className={`flex flex-col relative my-3  ${containerClass}`}>
      <div className="w-1/4 h-5 bg-white mb-1"></div>
      <div
        style={{ width: width, height: height }}
        className={`${styleClass} mt-2  bg-white  `}
      ></div>
    </div>
  );
}
InputScaleable.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  styleClass: PropTypes.string,
  containerClass: PropTypes.string,
};
