import React, { useEffect, useState } from "react";
import CourseCard from "../../components/pagesComponents/courses/CourseCard";
import CustomSearch from "../../components/reuseables/CustomSearch";
import CourseDetailesCard from "../../components/pagesComponents/courses/CourseDetailesCard";
import { Link } from "react-router-dom";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import ResultMessage from "../../components/reuseables/ResultMessage";
export default function CourseDetailes() {
  const [coursesList, setcoursesList] = useState({
    list: ["", "", "", "", "", "", "", "", "", ""],
    loader: { status: "loading", text: "" },
  });
  const [searchKey, setsearchKey] = useState("");
  const handleGetCourseList = () => {
    if (coursesList.loader.status !== "loading") {
      setcoursesList({
        list: ["", "", "", "", "", "", "", "", "", ""],
        loader: { status: "loading", text: "" },
      });
    }
    myFeach(
      `courses${searchKey ? `?searchKey=${searchKey}` : ""}`,
      "GET",
      {},
      "token"
    )
      .then(({ data }) => {
        setcoursesList({ list: data.courses, loader: { status: "done" } });
        if (data.courses.length == 0) {
          setcoursesList({
            list: data.courses,
            loader: {
              status: "failed",
              text: "لا يوجد نتائج حاول مرة أخرى",
            },
          });
        }
      })
      .catch((err) => {
        setcoursesList({
          loader: {
            status: "failed",
            text: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل تحميل الكورسات",
          },
        });
      });
  };
  useEffect(() => {
    handleGetCourseList();
  }, [searchKey]);
  return (
    <main>
      <div className="w-full flex flex-col md:flex-row">
        <div className="course_list w-full md:w-1/4 h-fit md:h-screen">
          <Link
            to="/add-course"
            className="gradient_border px-4 py-2 mb-8 block w-fit "
          >
            إضافة كورس جديد
          </Link>
          <CustomSearch
            loader={coursesList.loader}
            onchangeFun={(key) => setsearchKey(key)}
            width={"80%"}
          />
          <div className="flex md:flex-wrap md:h-screen md:overflow-y-auto md:flex-col whitespace-nowrap  h-72 no_wrap overflow-x-scroll md:overflow-x-scroll   ">
            {coursesList.list.map((course, index) => (
              <CourseCard
                loader={coursesList.loader}
                key={index}
                cardData={course}
              />
            ))}
            {coursesList.loader.status === "failed" && (
              <ResultMessage message={coursesList.loader?.text} />
            )}
          </div>
        </div>
        <div className="w-full md:w-3/4 bg-white p-8 shadow-md ">
          <CourseDetailesCard handleRegetList={handleGetCourseList} />
        </div>
      </div>
    </main>
  );
}

