import React from "react";
import PropTypes from "prop-types";

export default function RightLeftArrIcon({ color, type }) {
  return (
    <>
      {type == "right" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
           height="18"
          viewBox="0 0 6 12"
          fill="none"
        >
          <path
            d="M1 10.6666L4.55374 6.52059C4.81054 6.221 4.81054 5.77892 4.55374 5.47933L0.999999 1.33329"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      )}
      {type == "left" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
           height="18"
          viewBox="0 0 6 12"
          fill="none"
        >
          <path
            d="M5 1.33337L1.44626 5.47941C1.18946 5.779 1.18946 6.22108 1.44626 6.52067L5 10.6667"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      )}
    </>
  );
}
RightLeftArrIcon.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
};
