import React from "react";
import PropTypes from "prop-types";
import { cutString } from "../../../globalFunctions/TextModify";
import { Link } from "react-router-dom";

export default function CourseCard({ cardData, loader }) {
  return (
    <>
      {loader.status === "done" && (
        <Link
          to={`/course-detailes/${cardData.id}/${ cardData.name}`}
          className="w-64  h-60 my_rounded block bg-white shadow-md ml-4 my-4 p-4 relative "
        >
          {!cardData.hidden && (
            <div className="absolute bg_secondary top-0 left-0 px-6 py-1 my_rounded text-sm ">
              ظاهر
            </div>
          )}
          <figure className="w-full h-36 my_rounded">
            <div
              className="imge_bg my_rounded"
              style={{ backgroundImage: `url(${cardData.image})` }}
            ></div>
          </figure>
          <h4 className="mt-2">{cutString(cardData.name, 23)}</h4>
        </Link>
      )}
      {loader.status === "loading" && (
        <div
          className="w-60  h-60 my_rounded block bg-white shadow-md ml-4 my-4 p-4 relative "
        >
          <figure className="w-full h-36 my_rounded">
            <div className="imge_bg my_rounded bg_greay scaleAble "></div>
          </figure>
          <h4 className="mt-4 w-full h-8  bg_greay scaleAble"></h4>
        </div>
      )}
    </>
  );
}
CourseCard.propTypes = {
  cardData: PropTypes.any,
  loader: PropTypes.object,
};
