import React from "react";

export default function QuestionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        x="2"
        y="2"
        width="20"
        height="20"
        rx="5"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M11.5524 5.45215C9.39863 5.45215 7.65625 7.19453 7.65625 9.34835C7.65625 9.50335 7.71782 9.652 7.82743 9.7616C7.93703 9.8712 8.08568 9.93278 8.24068 9.93278C8.39568 9.93278 8.54433 9.8712 8.65393 9.7616C8.76354 9.652 8.82511 9.50335 8.82511 9.34835C8.82511 7.83974 10.0438 6.62101 11.5524 6.62101C13.0611 6.62101 14.2798 7.83974 14.2798 9.34835C14.2798 10.0123 14.1068 10.4549 13.8676 10.7977C13.6151 11.1585 13.2699 11.439 12.853 11.7578L12.7455 11.8396C11.9584 12.438 10.968 13.1908 10.968 14.803V14.9978C10.968 15.1528 11.0296 15.3015 11.1392 15.4111C11.2488 15.5207 11.3974 15.5823 11.5524 15.5823C11.7074 15.5823 11.8561 15.5207 11.9657 15.4111C12.0753 15.3015 12.1369 15.1528 12.1369 14.9978V14.803C12.1369 13.7768 12.6933 13.3513 13.5278 12.7131L13.5637 12.6858C13.9743 12.3718 14.4575 11.9946 14.826 11.4671C15.2079 10.9209 15.4486 10.2437 15.4486 9.34835C15.4486 7.19453 13.7063 5.45215 11.5524 5.45215Z"
        fill="white"
        stroke="white"
        strokeWidth="0.25"
      />
      <path
        d="M11.5508 18.1353L11.5508 17.1353"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
