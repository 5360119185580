import React from "react";
import PropTypes from "prop-types";
export default function ListIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
    >
      <path
        d="M28.3359 2L1.66927 2"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M28.3359 12L1.66927 12"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M28.3359 22H1.66927"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
ListIcon.propTypes = {
  color: PropTypes.string,
};
