import React from "react";
import PropTypes from "prop-types";
import RightLeftArrIcon from "../icons/RightLeftArrIcon";

export default function Pagenation({
  page,
  lastPage,
  setPage,
  disabled = false,
  totalResult,
  pageActualResult,
  name,
}) {
  return (
    <div className=" pagination flex-wrap">
      <h5 className="text_greay">
        إظهار {pageActualResult} {name} من أصل {totalResult} {name}
      </h5>
      <div className="flex items-center">
        <button
          onClick={() => setPage(page - 1)}
          disabled={page == 1 || disabled ? true : false}
          className="bg-white my_rounded w-fit h-fit p-3"
        >
          <RightLeftArrIcon
            type="right"
            color={page == 1 ? "#ABBED1" : "#1D68DF"}
          />
        </button>
        <div className="px-2 py-1 m-2 active_border h-fit w-fit">{page}</div>
        <button
          onClick={() => setPage(page + 1)}
          disabled={page == lastPage || disabled ? true : false}
          className="bg-white my_rounded w-fit h-fit p-3"
        >
          <RightLeftArrIcon
            type="left"
            color={page == lastPage ? "#ABBED1" : "#1D68DF"}
          />
        </button>
      </div>
    </div>
  );
}
Pagenation.propTypes = {
  page: PropTypes.any,
  name: PropTypes.any,
  lastPage: PropTypes.any,
  setPage: PropTypes.any,
  disabled: PropTypes.bool,
  totalResult: PropTypes.number,
  pageActualResult: PropTypes.bool,
};
