import React from "react";

export default function AddDocIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="44"
      viewBox="0 0 42 44"
      fill="none"
    >
      <path
        d="M34 22V15.4482C34 14.5125 33.6719 13.6063 33.0729 12.8875L25.1994 3.43926C24.4394 2.52729 23.3136 2 22.1265 2H18H10C5.58172 2 2 5.58172 2 10V34C2 38.4183 5.58172 42 10 42H20"
        stroke="#1D68DF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M28 36H40"
        stroke="#1D68DF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34 30L34 42"
        stroke="#1D68DF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 3V10C24 12.2091 25.7909 14 28 14H33"
        stroke="#1D68DF"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}
