import React from "react";

export default function RedWorning() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask
        id="mask0_3465_12266"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="1"
        y="2"
        width="18"
        height="16"
      >
        <path
          d="M10.4327 2.74948C10.3433 2.59507 10.1784 2.5 10 2.5C9.82158 2.5 9.65668 2.59507 9.56729 2.74948L1.56729 16.5677C1.47773 16.7223 1.47756 16.9131 1.56684 17.0679C1.65611 17.2228 1.82126 17.3182 2 17.3182H18C18.1787 17.3182 18.3439 17.2228 18.4332 17.0679C18.5224 16.9131 18.5223 16.7223 18.4327 16.5677L10.4327 2.74948Z"
          fill="white"
          stroke="white"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 14.2729V13.9092H10H9.5V14.2729H10H10.5ZM9.5 8.09146L9.50291 11.7278L10.0029 11.7274L10.5029 11.727L10.5 8.09066L10 8.09106L9.5 8.09146Z"
          stroke="black"
          strokeLinecap="round"
        />
      </mask>
      <g mask="url(#mask0_3465_12266)">
        <path d="M0 0H20V20H0V0Z" fill="#EE392B" />
      </g>
    </svg>
  );
}
